import { ReactNode, useContext } from 'react';

import { Pagination, SidebarNavigation, TablePageTemplate as NetfrontTablePageTemplate, PageContainer, ITableRequiredParameters } from '@netfront/ui-library';
import { PageLayout} from 'components'
import Link from 'next/link';
import { useAppHeaderHeight } from 'utils';

import { TablePageTemplateProps } from './TablePageTemplate.interfaces';

import { DashboardContext, formatDashboardItems } from '../../../context';



const TablePageTemplate = <T extends ITableRequiredParameters>({
  activePage,
  activeSubPage,
  activePath,
  breadcrumbItems = [],
  bulkActions,
  bulkActionPlaceHolder,
  childrenEnd,
  childrenMiddle,
  childrenStart,
  columns,
  data,
  description,
  handleAddNewClick,
  handleOnPageSizeChange,
  handleOnPaginate,
  handleSearch,
  informationBoxMessage,
  isLoading,
  isPaginationDisabled = false,
  pageSize = 10,
  pageTitle,
  searchPlaceholder = '',
  title,
  totalItems = 0,
  tableType = '',
  onSelectRows,
  size,
  icon,
  avatarImageSrc,
  children,
  renderSubComponent,
  topLevelChildren,
  initialSearchValue = '',
  isManagementLevel = false,
  hasSidebarNavigation = true,
  emptyTableMessage,
}: TablePageTemplateProps<T>) => {

  const { dashboardItems, dashboardLink } = useContext(DashboardContext);
  const headerHeight = useAppHeaderHeight();

  const Wrapper = ({ children: wrapperChildren }: { children: ReactNode }) => {
    if (!hasSidebarNavigation) return <>{wrapperChildren}</>;

    return (
      <SidebarNavigation
        activePage={activePage}
        activePath={activePath}
        activeSubPage={activeSubPage}
        dashboardLink={dashboardLink}
        dashboardLinkText={isManagementLevel ? 'Springboard': 'Dashboard'}
        dashboardLinkWrapper={({ children: dashboardLinkChildren }: { children: ReactNode }) => <Link href={String(dashboardLink)}>{dashboardLinkChildren}</Link>}
        navigationItems={formatDashboardItems(dashboardItems)}
        offsetPositioning={headerHeight}
      >
        {wrapperChildren}
      </SidebarNavigation>
    )
  }


  return (
    <PageLayout
      additionalClassNames="c-table-page-template"
      isManagementLevel={isManagementLevel}
      isPreloaderVisible={isLoading}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >

      <Wrapper>
        {topLevelChildren}
        <PageContainer>
          <NetfrontTablePageTemplate<T>
            addNewOnClick={handleAddNewClick}
            avatarImageSrc={avatarImageSrc}
            breadcrumbItems={breadcrumbItems}
            bulkActionPlaceHolder={bulkActionPlaceHolder}
            bulkActions={bulkActions}
            childrenEnd={childrenEnd}
            childrenMiddle={childrenMiddle}
            childrenStart={childrenStart}
            columns={columns}
            data={data}
            emptyStateImageUrl="/images/empty-state.webp"
            emptyTableMessage={emptyTableMessage}
            icon={icon}
            informationBoxMessage={informationBoxMessage}
            initialSearchValue={initialSearchValue}
            pageTitle={title}
            renderSubComponent={renderSubComponent}
            searchPlaceholder={searchPlaceholder}
            size={size}
            tableType={tableType}
            title={title}
            onSearch={handleSearch}
            onSelectRows={onSelectRows}
          >
            {children}
          </NetfrontTablePageTemplate>

          {totalItems > pageSize && handleOnPaginate && handleOnPageSizeChange && (
            <Pagination
              isLoadMoreDisabled={isPaginationDisabled}
              pageSize={pageSize}
              totalItems={totalItems}
              onPageChange={handleOnPaginate}
              onPageSizeChange={handleOnPageSizeChange}
            />
          )}
        </PageContainer>

      </Wrapper>
    </PageLayout>
  );
};

export { TablePageTemplate };
