import { useContext } from 'react';

import { useUser } from '@netfront/gelada-identity-library';
import { BasicPageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useSendHowlerContactForm, useToast } from 'hooks';
import Link from 'next/link';

import { ContactForm } from '../../../Forms';

const PartnerManagementContactPage = () => {
  const { partner } = useContext(CachingEntitiesContext);

  const { getUser } = useUser();
  const loggedUser = getUser();

  const { handleToastError, handleToastSuccess } = useToast();

  const { handleSendHowlerContactForm, isLoading = false } = useSendHowlerContactForm({
    onCompleted: ({ isCompleted }) => {
      handleToastSuccess({
        message: isCompleted ? 'Your message has been sent' : 'Your message has not been sent',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  return (
    <BasicPageTemplate
      activePage="support"
      breadcrumbItems={[{
        content: <Link href={`/partner/dashboard`}><span>Partners</span></Link>,
        key: 'partners',
        
      },
      {
        content: 'Support',
        key: 'support',
      }]}
      description="Partner support"
      informationBoxMessage="Get in touch with support by completing the form or using help scout below"
      pageTitle="Partner management support"
      title="Support"
    >
      <div className="c-contact-form-container">
        <ContactForm
          isSubmitting={isLoading}
          onSend={({ message, organisationName }) => {
            if (!loggedUser) return;
            const { email, firstName, lastName } = loggedUser as unknown as { email: string; firstName: string; lastName: string };
            void handleSendHowlerContactForm({
              email,
              firstName,
              lastName,
              message,
              placeholders: [
                {
                  key: '[Firstname]',
                  value: firstName,
                },
                {
                  key: '[Lastname]',
                  value: lastName,
                },
                {
                  key: '[Email]',
                  value: email,
                },
                {
                  key: '[Organisation]',
                  value: partner?.name ?? organisationName,
                },
                {
                  key: '[Message]',
                  value: message,
                },
              ],
              projectId: String(process.env.REACT_APP_PROJECT_ID),
            });
          }}
        />
      </div>
    </BasicPageTemplate>
  );
};

export { PartnerManagementContactPage };
