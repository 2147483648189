import { gql } from '@apollo/client';

const GET_TAMARIN_INSIGHTS = gql`
query tamarinInsights($start:DateTime!,$end:DateTime!){
  report{
    insights(start:$start,end:$end){
      dailyCreation{
        count
        date
      }
      dailyScans{
        count
        date
      }
    }
  }
}
`;

export { GET_TAMARIN_INSIGHTS };
