import { gql } from '@apollo/client';
const GET_GELADA_ORGANISATION_PROJECTS_QUERY = gql`
  query getGeladaOrganisationProjects(
    $organisationKey: String!
    $after: String
    $first: Int
    $filter: String
    $shouldIncludeProjectDomain: Boolean = false
    $shouldIncludeProjectSettings: Boolean = false
    $shouldIncludeProjectTheme: Boolean = false
  ) {
    project {
      organisationProjects(organisationKey: $organisationKey, first: $first, filter: $filter, after: $after) {
        edges {
          node {
            backOfficeUrl
            description
            domain @include(if: $shouldIncludeProjectDomain) {
              redirectAfterLogin
              redirectAfterRegistration
              value
            }
            features
            guid
            hasMobileApplication
            id
            isCustomBuild
            logo {
              assetId
              contentType
              presignedUrl
              s3Key
            }
            name
            organisation {
              name
              key
            }
            organisationId
            services
            settings @include(if: $shouldIncludeProjectSettings) {
              accessTokenExpiry
              hasHyperlinksInPost
              hasModuleProgression
              hasScheduler
              impersonateUserLink
              refreshTokenExpiry
              hasGroupRequest
            }
            theme @include(if: $shouldIncludeProjectTheme) {
              backGroundColor
              buttonColor
              customCss
              footerColor
              headerColor
              linkColor
              sideColor
            }
            backOfficeUrl
          }
        }
        totalCount
      }
    }
  }
`;
export { GET_GELADA_ORGANISATION_PROJECTS_QUERY };
