import React, { useEffect, useState, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { TablePageTemplate, UpsertTeamSidebarView } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetTeam, useToast, useProtectedRoute } from 'hooks';
import { IDBPartnerTeam } from 'interfaces';
import last from 'lodash.last';
import Link from 'next/link';

import { TEAMS_TABLE_COLUMNS } from './PartnerManagementTeamsPage.constants';
import { getTeamsTableData } from './PartnerManagementTeamsPage.helpers';
import { ITeamsTableData } from './PartnerManagementTeamsPage.interfaces';

const PartnerManagementTeamsPage = () => {
  const { handleToastError } = useToast();

  const { partner } = useContext(CachingEntitiesContext);

  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();

  const [allTeams, setAllTeams] = useState<IDBPartnerTeam[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<IDBPartnerTeam[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedTeam, setSelectedTeam] = useState<IDBPartnerTeam>();
  const [totalTeams, setTotalTeams] = useState<number>(0);
  const [teamsTableData, setTeamsTableData] = useState<ITeamsTableData[]>([]);

  const token = getAccessTokenCookie();

  const {
    handleGetTeam,
    handleFetchMoreTeam,
    isLoading: isGetTeamLoading,
  } = useGetTeam({
    fetchPolicy: 'no-cache',
    onCompleted: ({ partnerTeamConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const partnerTeams = edges.map(({ node }) => node);

      setFilteredTeams(partnerTeams);
      setAllTeams(partnerTeams);

      setIsLoadMoreDisabled(partnerTeams.length >= totalCount || totalCount <= pageSize);
      setTotalTeams(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });


  const handleFilterSearch = (value: string) => {
    setFilter(value);

    setFilteredTeams(
      allTeams.filter(
        ({
          user: {
            firstname,
            lastname,
            credential: { email },
          },
        }) =>
          firstname.toLowerCase().includes(value.toLowerCase()) ||
          email.toLowerCase().includes(value.toLowerCase()) ||
          lastname.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  const handleSideBarClose = () => {
    setSelectedTeam(undefined);
    setIsSidebarOpen(false);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    void handleFetchMoreTeam({
      after: lastCursor,
      first: selectedPageSize,
      partnerId: Number(partner?.id),
    });
  };

  const handleSideBarAddUserOpen = () => {
    setSelectedTeam(undefined);
    setIsSidebarOpen(true);
  };

  useEffect(() => {
    if (filter === '') setFilteredTeams(allTeams);

    setFilteredTeams(
      allTeams.filter(
        ({
          user: {
            firstname,
            lastname,
            credential: { email },
          },
        }) =>
          firstname.toLowerCase().includes(String(filter).toLowerCase()) ||
          email.toLowerCase().includes(String(filter).toLowerCase()) ||
          lastname.toLowerCase().includes(String(filter).toLowerCase()),
      ),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setTeamsTableData(
      getTeamsTableData({
        teams: filteredTeams,
        onSettingsButtonClick: (id) => {
          setIsSidebarOpen(true);
          setSelectedTeam(filteredTeams.find(({ user: { id: userId } }) => id === userId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTeams]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetTeam({
      partnerId: Number(partner?.id),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pageSize]);

  return (
    <>
      <TablePageTemplate<ITeamsTableData>
        activePage="team"
        breadcrumbItems={[{
          content: <Link href={`/partner/dashboard`}><span>Partners</span></Link>,
          key: 'partners',
          
        },
        {
          content: 'Team',
          key: 'team',
        }]}
        columns={TEAMS_TABLE_COLUMNS}
        data={teamsTableData}
        description="Partner teams"
        handleAddNewClick={handleSideBarAddUserOpen}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMoreTeam({
            after: lastCursor,
            first: pageSize,
            partnerId: Number(partner?.id),
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage="Manage and invite users to your project."
        initialSearchValue={filter}
        isLoading={isGetTeamLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        pageTitle="Partner management teams"
        searchPlaceholder="Search"
        size="small"
        tableType="users"
        title="Teams"
        totalItems={totalTeams}
      />

      <UpsertTeamSidebarView
        handleSideBarClose={handleSideBarClose}
        isSideBarOpen={isSideBarOpen}
        selectedTeam={selectedTeam}
      />
    </>
  );
};

export { PartnerManagementTeamsPage };
