import { useContext, useEffect, useState } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import { CopyIcon, FocusIcon, InsightsIcon, OrganisationIcon, PayoutsIcon, ProjectsIcon, ReferralsIcon, SettingsIcon, SupportIcon, TeamIcon, ClockIcon } from '@netfront/ui-library';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import { CachingEntitiesContext } from 'context/CachingEntitiesContext';

import DashboardContext from './DashboardContext';
import { filterVisibleItems } from './DashboardContext.helpers';
import { DashboardContextProps, IDashboardItemsList } from './DashboardContext.interfaces';


export function DashboardProvider({ children }: DashboardContextProps) {
  const { isDomainReady } = useDomain();
  const { handleToastSuccess } = useToast();
  const { partner } = useContext(CachingEntitiesContext);
  const [dashboardItemsList, setDashboardItemsList] = useState<IDashboardItemsList[]>([]);
  const [dashboardLink, setDashboardLink] = useState<string>('');
  
  const { pathname } = useRouter();

  const handleCopyPartnerCode = (copiedValue: string) => {
    const copyCode = async () =>  await navigator.clipboard.writeText(copiedValue);

    void copyCode();

    handleToastSuccess({
      message: `Partner code copied successfully`,
    });
  };


  useEffect(() => {
    if (!(pathname && isDomainReady)) {
      return;
    }

    const isManagementLevel = pathname.includes('/management/');

    const cardItems: IDashboardItemsList[] = [
      {
        id: 'global-insights',
        description: 'Insights page',
        isVisible: isManagementLevel,
        href: '',
        icon: InsightsIcon,
        title: 'Global insights',
        subItems: [
          {
            id: 'overall',
            description: 'Overall insights page',
            isVisible: isManagementLevel,
            href: '/management/global-insights/overall',
            icon: InsightsIcon,
            title: 'Overall insights',
          },
          {
            id: 'time-bound',
            description: 'Time bound insights page',
            isVisible: isManagementLevel,
            href: '/management/global-insights/time-bound',
            icon: ClockIcon,
            title: 'Time bound insights',
          }
        ]
      },
      {
        id: 'partners',
        description: 'Partners page',
        isVisible: isManagementLevel,
        href: '/management/partners',
        icon: FocusIcon,
        title: 'Partners',
      },
      {
        id: 'organisations',
        description: 'Organisations page',
        isVisible: isManagementLevel,
        href: '/management/organisations',
        icon: OrganisationIcon,
        title: 'Organisations',
      },
      {
        id: 'projects',
        description: 'Projects page',
        isVisible: isManagementLevel,
        href: '/management/projects',
        icon: ProjectsIcon,
        title: 'Projects',
      },
      {
        id: 'referrals',
        description: 'Referrals page',
        isVisible: Boolean(partner) && !isManagementLevel,
        href: '/partner/referrals',
        icon: ReferralsIcon,
        title: 'Referrals',
      },
      {
        id: 'payouts',
        description: 'Payouts page',
        isVisible: Boolean(partner) && !isManagementLevel,
        href: '/partner/payouts',
        icon: PayoutsIcon,
        title: 'Payouts',
      },
      {
        id: 'support',
        description: 'Support page',
        isVisible: Boolean(partner) && !isManagementLevel,
        href: '/partner/support',
        icon: SupportIcon,
        title: 'Support',
      },
      {
        id: 'team',
        description: 'Team page',
        isVisible: Boolean(partner) && !isManagementLevel,
        href: '/partner/team',
        icon: TeamIcon,
        title: 'Team',
      },
      {
        id: 'settings',
        description: 'Settings page',
        isVisible: Boolean(partner) && !isManagementLevel,
        href: '/partner/settings',
        icon: SettingsIcon,
        title: 'Settings',
      },
      {
        id: 'copy-partner-code',
        description: 'Copy partner code',
        isVisible: Boolean(partner) && !isManagementLevel,
        href: '',
        onClick: () => handleCopyPartnerCode(String(partner?.code)),
        icon: CopyIcon,
        title: 'Partner code:',
      },
    ];

    
    setDashboardLink(isManagementLevel ? '/management/springboard' : '/partner/dashboard');
    setDashboardItemsList(filterVisibleItems(cardItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isDomainReady]);

  return (
    <DashboardContext.Provider
      value={{
        dashboardItems: dashboardItemsList,
        dashboardLink,
        isLoading: dashboardItemsList.length === 0
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
