import format from 'date-fns/format';

import { IGetTransactionsTableDataParams, ITransactionsTableData } from './TransactionsPage.interfaces';

const getTransactionsTableData = ({ transactions, onSettingsButtonClick }: IGetTransactionsTableDataParams): ITransactionsTableData[] => {
  return transactions.map((transaction, index): ITransactionsTableData => {
    const { createdAtUtc, customer, amount, description, email, status } = transaction;
    return {
      id: index,
      amount: amount / 100,
      customer,
      description: description ?? '',
      email,
      paymentDate: `${format(Date.parse(String(createdAtUtc)), 'dd MMMM yyyy')}`,
      status,
      settingsButtonData: {
        transaction,
        onClick: onSettingsButtonClick,
      }
    };
  });
};

export { getTransactionsTableData };
