import { IGetOrganisationsTableDataParams, IOrganisationsTableData } from './OrganisationsPage.interfaces';

const getOrganisationsTableData = ({ organisations, onSettingsButtonClick }: IGetOrganisationsTableDataParams): IOrganisationsTableData[] => {
  return organisations.map(({ id, name, key }): IOrganisationsTableData => {
    return {
      id,
      key,
      name,
      settingsButtonData: {
        id: key,
        onClick: onSettingsButtonClick,
      }
    };
  });
};

export { getOrganisationsTableData };
