import { useContext, useEffect, useState } from 'react';

import { IDBGeladaOrganisation } from '@netfront/gelada-identity-library';
import { ImageAssetIcon } from '@netfront/ui-library';
import { TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetAllOrganisations } from 'hooks';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ORGANISATIONS_TABLE_COLUMNS } from './OrganisationsPage.constants';
import { getOrganisationsTableData } from './OrganisationsPage.helpers';
import { IOrganisationsTableData } from './OrganisationsPage.interfaces';

const OrganisationsPage = () => {
  const [totalOrganisations, setTotalOrganisations] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState<string>('');
  const [organisationsTableData, setOrganisationsTableData] = useState<IOrganisationsTableData[]>([]);
  const [allOrganisations, setAllOrganisations] = useState<IDBGeladaOrganisation[]>();
  const [lastCursor, setLastCursor] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);

  const { dashboardUrl } = useContext(CachingEntitiesContext);
  const { push } = useRouter();

  const onDashboardButtonClick = (id: string) => {
    const projectUrl = `${String(dashboardUrl)}/${id}/`;
    void push(projectUrl);
  };

  const { 
    handleGetAllOrganisations, 
    handleFetchMoreOrganisations,
    isLoading = false, 
  } = useGetAllOrganisations({
    fetchPolicy: 'no-cache',
    onCompleted({ organisations: { edges, totalCount = 0 } }) {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }
      const organisations = edges.map((r) => r.node);

      setIsLoadMoreDisabled(organisations.length >= totalCount || totalCount <= pageSize);
      setTotalOrganisations(totalCount);
      setAllOrganisations(organisations);
    },
  });

  useEffect(() => {
    handleGetAllOrganisations({ first: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    void handleFetchMoreOrganisations({
      after: lastCursor,
      first: pageSize,
      filter,
    });
  };

  const handleFilterSearch = (value: string) => {
    handleGetAllOrganisations({ 
      first: pageSize, 
      filter: value 
    });

    setFilter(value);
  };


  useEffect(() => {
    if (!allOrganisations) {
      return;
    }

    setOrganisationsTableData(
      getOrganisationsTableData({
        organisations: allOrganisations,
        onSettingsButtonClick: onDashboardButtonClick,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrganisations]);

  return (
    <TablePageTemplate<IOrganisationsTableData>
      activePage="organisations"
      breadcrumbItems={[{
        content: <Link href={`/management/springboard`}><span>Springboard</span></Link>,
        key: 'springboard',
      },
      {
        content: 'Organisations',
        key: 'organisations',
      }]}
      columns={ORGANISATIONS_TABLE_COLUMNS}
      data={organisationsTableData}
      description="Organisations"
      handleOnPageSizeChange={handlePageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMoreOrganisations({
          after: lastCursor,
          first: pageSize,
          filter,
        });
      }}
      handleSearch={handleFilterSearch}
      icon={ImageAssetIcon}
      informationBoxMessage="Manage active organisations"
      initialSearchValue={filter}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      pageSize={pageSize}
      pageTitle="Organisations"
      searchPlaceholder="Search"
      size="small"
      tableType="organisations"
      title="Organisations"
      totalItems={totalOrganisations}
      isManagementLevel
    />
  );
};
export { OrganisationsPage };
