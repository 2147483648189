import { gql } from '@apollo/client';

const GET_KANZI_INSIGHTS = gql`
  query kanziInsights($start: DateTime!, $end: DateTime!) {
    report {
      insights(start: $start, end: $end) {
        pdfs {
          count
          date
        }
        speeches {
          count
          date
        }
        translate {
          count
          date
        }
      }
    }
  }
`;

export { GET_KANZI_INSIGHTS };
