import { useTamarinLazyQuery } from 'hooks/apollo/useTamarinLazyQuery';

import { GET_TAMARIN_INSIGHTS } from './useGetTamarinInsights.graphql';
import {
  IGetTamarinInsightsQueryGraphQLResponse,
  IGetTamarinInsightsQueryVariables,
  IHandleGetTamarinInsightsParams,
  IUseGetTamarinInsights,
  IUseGetTamarinInsightsOptions,
} from './useGetTamarinInsights.interfaces';

const useGetTamarinInsights = (options?: IUseGetTamarinInsightsOptions): IUseGetTamarinInsights => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetTamarinInsightsOptions);

  const [executeGetTamarinInsights, { loading: isLoading }] = useTamarinLazyQuery<
    IGetTamarinInsightsQueryGraphQLResponse,
    IGetTamarinInsightsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          report: {
            insights: { dailyCreation, dailyScans },
          },
        } = data;

        onCompleted({
          dailyCreation,
          dailyScans,
        });
      },
      onError,
    },
    query: GET_TAMARIN_INSIGHTS,
    token,
  });

  const handleGetTamarinInsights = ({ end, start }: IHandleGetTamarinInsightsParams) => {
    void executeGetTamarinInsights({
      variables: {
        end,
        start,
      },
    });
  };

  return {
    handleGetTamarinInsights,
    isLoading,
  };
};

export { useGetTamarinInsights };
