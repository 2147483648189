import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ITransactionsTableData } from './TransactionsPage.interfaces';

const TRANSACTIONS_TABLE_COLUMNS: ReadonlyArray<Column<ITransactionsTableData>> = [
  {
    accessor: 'paymentDate',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Payment date</div>,
    width: '20%',
  },
  {
    accessor: 'customer',
    Cell: ({ value }) => <>{value.name}</>,
    Header: () => <div>Name</div>,
    width: '20%',
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Description</div>,
    width: '30%',
  },
  {
    accessor: 'amount',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Amount</div>,
    width: '10%',
  },
  {
    accessor: 'status',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Status</div>,
    width: '15%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { transaction, onClick } }) => (
      <SettingsButton supportiveText="View transaction" onClick={() => onClick(transaction)} />
    ),
    width: '5%',
  },
];

export { TRANSACTIONS_TABLE_COLUMNS };
