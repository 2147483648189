import { useMuriquiLazyQuery } from 'hooks/useMuriquiLazyQuery';

import { GET_MURIQUI_INSIGHTS } from './useGetMuriquiInsights.graphql';
import {
  IGetMuriquiInsightsQueryGraphQLResponse,
  IGetMuriquiInsightsQueryVariables,
  IHandleGetMuriquiInsightsParams,
  IUseGetMuriquiInsights,
  IUseGetMuriquiInsightsOptions,
} from './useGetMuriquiInsights.interfaces';

const useGetMuriquiInsights = (options?: IUseGetMuriquiInsightsOptions): IUseGetMuriquiInsights => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetMuriquiInsightsOptions);

  const [executeGetMuriquiInsights, { loading: isLoading }] = useMuriquiLazyQuery<
  IGetMuriquiInsightsQueryGraphQLResponse,
  IGetMuriquiInsightsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          insights: {
            insights: { payingProject, usages },
          },
        } = data;

        onCompleted({
          payingProject,
          usages,
        });
      },
      onError,
    },
    query: GET_MURIQUI_INSIGHTS,
    token,
  });

  const handleGetMuriquiInsights = ({ end, product, start }: IHandleGetMuriquiInsightsParams) => {
    void executeGetMuriquiInsights({
      variables: {
        end,
        product,
        start,
      },
    });
  };

  return {
    handleGetMuriquiInsights,
    isLoading,
  };
};

export { useGetMuriquiInsights };
