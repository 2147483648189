import { useState, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { getAccessTokenCookie } from '@netfront/common-library';
import { IDBGeladaProject, IGeladaOrganisation } from '@netfront/gelada-identity-library';
import { SelectWithSearch, CircleAddIcon, Container } from '@netfront/ui-library';
import { CreateOrganisationSidebarView, TablePageTemplate, UpsertProjectSidebarView } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetGeladaOrganisationProjects, useProtectedRoute, useToast } from 'hooks';
import last from 'lodash.last';

import { PROJECTS_TABLE_COLUMNS } from './PartnerManagementDashboardPage.constants';
import { getProjectsTableData } from './PartnerManagementDashboardPage.helpers';
import { IOrganisationListItem, IProjectsTableData } from './PartnerManagementDashboardPage.interfaces';



const PartnerManagementDashboardPage = () => {
  const { handleToastSuccess, handleToastError } = useToast();
  const { isAuthenticated } = useProtectedRoute();
  const { partner, dashboardUrl } = useContext(CachingEntitiesContext);
  const token = getAccessTokenCookie();

  // Organisation states
  const [isAddOrganisationSideBarOpen, setIsAddOrganisationSideBarOpen] = useState<boolean>(false);
  const [isSearchContentVisible, setIsSearchContentVisible] = useState<boolean>(false);
  const [organisationsDropdownItems, setOrganisationsDropdownItems] = useState<IOrganisationListItem[]>([]);
  const [isOrganisationLinked, setIsOrganisationLinked] = useState<boolean>(false);
  const [organisationItem, setOrganisationItem] = useState<IOrganisationListItem>();

  // Project states
  const [allProjects, setAllProjects] = useState<IDBGeladaProject[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<IDBGeladaProject[]>([]);
  const [filter, setFilter] = useState<string>();
  const [lastCursor, setLastCursor] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isUpsertProjectSideBarOpen, setIsUpsertProjectSideBarOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [projectsTableData, setProjectsTableData] = useState<IProjectsTableData[]>([]);
  const [selectedProject, setSelectedProject] = useState<IDBGeladaProject>();
  const [totalOrganisationProjects, setTotalOrganisationProjects] = useState<number>(0);

  // Organisation functions
  const handleCreateAccount = (account: IGeladaOrganisation) => {
    const { id, name, key } = account;

    const newAccountItem = {
      id: id,
      key: key,
      label: name,
    };

    handleOrganisationSearchItemClick(newAccountItem);
    setOrganisationItem(newAccountItem);
    handleToastSuccess({
      message: `Account created successfully`,
    });

    setIsAddOrganisationSideBarOpen(false);
  };

  const handleLinkAccount = (isLinked: boolean) => {
    setIsOrganisationLinked(isLinked);

    handleToastSuccess({
      message: `Account linked successfully`,
    });
  };

  const handleOrganisationSearchItemClick = (item: IOrganisationListItem) => {
    setOrganisationItem(item);
  };

  // Project functions 
  const {
    handleGetGeladaOrganisationProjects,
    handleFetchMoreGetGeladaOrganisationProjects,
    isLoading: isGetOrganisationProjectsLoading = false,
  } = useGetGeladaOrganisationProjects({
    fetchPolicy: 'no-cache',
    onCompleted: ({ projectsConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const organisationProjects = edges.map(({ node }) => node);

      setAllProjects(organisationProjects);
      setIsLoadMoreDisabled(organisationProjects.length >= totalCount || totalCount <= pageSize);
      setTotalOrganisationProjects(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleAddNewProjectClick = () => {
    setSelectedProject(undefined);
    setIsUpsertProjectSideBarOpen(true);
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);

    if (!organisationItem) return;

    void handleGetGeladaOrganisationProjects({
      organisationKey: organisationItem.key,
      first: pageSize,
      shouldIncludeProjectTheme: true,
      filter: value,
    });
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    if (!organisationItem) return;
    setPageSize(selectedPageSize);
    void handleFetchMoreGetGeladaOrganisationProjects({
      after: lastCursor,
      first: pageSize,
      organisationKey: organisationItem.key,
      filter, 
    });
  };

  const handleSideBarClose = () => {
    setSelectedProject(undefined);
    setIsUpsertProjectSideBarOpen(false);
  };

  const handleUpsertProject = () => {
    if (!organisationItem) return;
    
    void handleGetGeladaOrganisationProjects({
      organisationKey: organisationItem.key,
      first: pageSize,
      shouldIncludeProjectTheme: true,
      filter,
    });

    handleSideBarClose();
  };



  // Organisation useEffects
  useEffect(() => {
    if (!partner) {
      return;
    }

    const {
      organisations: { edges: organisationsEdges },
    } = partner;

    const organisationsList = organisationsEdges.map(({ node }) => node);

    const organisationsSelectSearchList = organisationsList.map(
      ({ id, key, name }): IOrganisationListItem => ({
        id: id,
        key: key,
        label: name,
      }),
    );

    setOrganisationsDropdownItems(organisationsSelectSearchList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner?.organisations]);

  useEffect(() => {
    if (!(organisationItem && isOrganisationLinked)) {
      return;
    }

    setOrganisationsDropdownItems((currentState) => [...currentState, organisationItem]);
  }, [organisationItem, isOrganisationLinked]);

  // Project useEffects
  useEffect(() => {
    if (!(isAuthenticated && organisationItem)) {
      return;
    }

    void handleGetGeladaOrganisationProjects({
      organisationKey: organisationItem.key,
      first: pageSize,
      shouldIncludeProjectTheme: true,
      filter,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, organisationItem, pageSize]);

  useEffect(() => {
    setProjectsTableData(
      getProjectsTableData({
        dashboardUrl,
        projects: filteredProjects,
        onSettingsButtonClick: (id) => {
          setIsUpsertProjectSideBarOpen(true);
          setSelectedProject(filteredProjects.find(({ id: projectId }) => id === projectId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProjects, dashboardUrl]);

  useEffect(() => {
    if (!filter) {
      setFilteredProjects(allProjects);
    } else {
      setFilteredProjects(allProjects.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase())));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <TablePageTemplate<IProjectsTableData>
        activePage="dashboard"
        breadcrumbItems={[]}
        columns={PROJECTS_TABLE_COLUMNS}
        data={projectsTableData}
        description="Manage partner"
        handleAddNewClick={organisationItem ? handleAddNewProjectClick: undefined}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          if (!organisationItem) return;
          await handleFetchMoreGetGeladaOrganisationProjects({
            after: lastCursor,
            first: pageSize,
            organisationKey: organisationItem.key,
            filter,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage="Manage client groups and spaces"
        initialSearchValue={filter}
        isLoading={isGetOrganisationProjectsLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        pageTitle="Projects"
        searchPlaceholder="Search"
        size="small"
        tableType="Projects"
        title="Dashboard"
        topLevelChildren={(
          <div className="c-dashboard-organisation-controller">
            <div className="c-page-container">
              <Container size="4x-small">
                <SelectWithSearch
                  buttonText="Select organisation"
                  customButtonIcon={CircleAddIcon}
                  customButtonText="Add new account"
                  defaultValue={organisationItem ? String(organisationItem.label) : ''}
                  hasPadding={false}
                  id="organisation_select"
                  isDisplaySearchContent={isSearchContentVisible}
                  isLabelHidden={false}
                  labelFontWeight="bold"
                  labelText="Switch account"
                  searchList={organisationsDropdownItems}
                  tooltipPosition="start"
                  tooltipText="Select organisation"
                  hasLabelPadding
                  isAvatarVisible
                  onCustomButtonClick={() => setIsAddOrganisationSideBarOpen(true)}
                  onDisplaySearchContent={() => setIsSearchContentVisible(!isSearchContentVisible)}
                  onSearchItemClick={(e) => {
                    const dropDownItem = organisationsDropdownItems.find((r) => r.id === e);
                    if (dropDownItem) {
                      setOrganisationItem(dropDownItem);
                    }
                  }}
                />
              </Container>
            </div>
          </div>
        )}
        totalItems={totalOrganisationProjects}

      />
      {organisationItem && (
        <UpsertProjectSidebarView
          handleSideBarClose={handleSideBarClose}
          isSideBarOpen={isUpsertProjectSideBarOpen}
          organisationId={Number(organisationItem.id)}
          project={selectedProject}
          onUpsert={handleUpsertProject}
        />
      )}

      

      <CreateOrganisationSidebarView
        handleSideBarClose={() => setIsAddOrganisationSideBarOpen(false)}
        isSideBarOpen={isAddOrganisationSideBarOpen}
        onCreated={handleCreateAccount}
        onLinked={handleLinkAccount}
      />
    </>
  );
};

export { PartnerManagementDashboardPage };
