import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_PARTNER_MUTATION } from './useUpdatePartner.graphql';
import {
  IUpdatePartnerMutationGraphQLResponse,
  IUpdatePartnerMutationVariables,
  IHandleUpdatePartnerParams,
  IUseUpdatePartnerOptions,
  IUseUpdatePartner,
} from './useUpdatePartner.interfaces';

const useUpdatePartner = (options?: IUseUpdatePartnerOptions): IUseUpdatePartner => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdatePartnerOptions);

  const [executeUpdatePartner, { loading: isLoading }] = useLoggedGeladaMutation<
  IUpdatePartnerMutationGraphQLResponse,
  IUpdatePartnerMutationVariables
  >({
    mutation: mutation ?? UPDATE_PARTNER_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partner: { updatePartner: isCompleted },
        } = data;

        onCompleted({
          result: isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdatePartner = ({ input }: IHandleUpdatePartnerParams) => {
    void executeUpdatePartner({
      variables: {
        input,
      },
    });
  };

  return {
    handleUpdatePartner,
    isLoading,
  };
};

export { useUpdatePartner };
