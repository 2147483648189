import { useState } from 'react';

import { AvailableChartProps, DoughnutChartCard, ChartPicker, AvailableChart } from '@netfront/ui-library';

import { ActiveProjectsProps } from './ActiveProjects.interfaces';

const ActiveProjects = ({ totalCount = 0 }: ActiveProjectsProps) => {
  const LABEL = 'Total active projects';

  const [activeChart, setActiveChart] = useState<AvailableChart>('Doughnut');

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const doughnutData = [
    {
      backgroundColor: '#972868',
      borderColor: '#972868',
      label: 'Active',
      value: totalCount,
    }
  ];

  const availableCharts: AvailableChartProps[] = [
    { value: 'Doughnut', component: <DoughnutChartCard feed={doughnutData} label={LABEL} /> },
  ];

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_active_projects_chart_picker"
      onChange={onActiveChartChange}
    />
  );
};
export { ActiveProjects };
