import format from 'date-fns/format';

import { IGetPayoutsTableDataParams, IPayoutsTableData } from './PayoutsPage.interfaces';

const getPayoutsTableData = ({ payouts, onBreakdownButtonClick }: IGetPayoutsTableDataParams): IPayoutsTableData[] => {
  return payouts.map(({ amount, end, start }, index): IPayoutsTableData => {
    return {
      amount,
      id: index + 1,
      breakdownButtonData: {
        id: index + 1,
        onClick: onBreakdownButtonClick,
      },
      period: `${format(Date.parse(String(start)), 'do MMMM')} - ${format(Date.parse(String(end)), 'do MMMM')}`,
    };
  });
};

export { getPayoutsTableData };
