import { IGetProjectsTableDataParams, IProjectsTableData } from './PartnerManagementDashboardPage.interfaces';

const getProjectsTableData = ({ dashboardUrl, projects, onSettingsButtonClick }: IGetProjectsTableDataParams): IProjectsTableData[] => {
    
  return projects.map(({ id, name, organisationId }): IProjectsTableData => {
    return {
      name,
      id,
      settingsButtonData: {
        dashboardUrl,
        id,
        organisationId,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getProjectsTableData };
