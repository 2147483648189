import React from 'react';

import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ITeamsTableData } from './PartnerManagementTeamsPage.interfaces';

const TEAMS_TABLE_COLUMNS: ReadonlyArray<Column<ITeamsTableData>> = [
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div className="c-teams-table__teams-cell">{value}</div>,
    Header: () => <div>First name</div>,
    width: '30%'
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Last name</div>,
    width: '30%'
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div className="c-teams-table__teams-cell">{value}</div>,
    Header: () => <div>Email</div>,
    width: '35%'
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => (
      <SettingsButton supportiveText="Update team" onClick={() => onClick(id)} />
    ),
    width: '5%',
  },
];

export { TEAMS_TABLE_COLUMNS };
