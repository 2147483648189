import { gql } from '@apollo/client';

const GET_ALL_PROJECTS = gql`
  query getAllProjects($filter: String, $after: String, $first: Int!) {
    project {
      getAll(after: $after, first: $first, filter: $filter) {
        totalCount
        edges {
          cursor
          node {
            name
            id
            organisationId
          }
        }
      }
    }
  }
`;

export { GET_ALL_PROJECTS };
