import { useEffect, useState } from 'react';

import { saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import { ImageAssetIcon } from '@netfront/ui-library';
import { TablePageTemplate } from 'components';
import { useGetAllPartners } from 'hooks';
import { IDBPartner } from 'interfaces';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { PARTNERS_TABLE_COLUMNS } from './PartnersPage.constants';
import { getPartnersTableData } from './PartnersPage.helpers';
import { IPartnersTableData } from './PartnersPage.interfaces';

const PartnersPage = () => {
  const [totalPartners, setTotalPartners] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState<string>('');
  const [partnersTableData, setPartnersTableData] = useState<IPartnersTableData[]>([]);
  const [allPartners, setAllPartners] = useState<IDBPartner[]>();
  const [lastCursor, setLastCursor] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);

  const { push } = useRouter();
  const onDashboardButtonClick = (id: number) => {
    saveItemWithExpiryToLocalStorage('partner', String(id), {
      currentTimeValueInMilliseconds: new Date().getTime(),
      expiryTime: {
        unit: 'hours',
        value: 6,
      },
    });
    
    setTimeout(() => {
      void push('/partner/dashboard');
    }, 400);
  };
  const { handleGetAllPartners, isLoading, handleFetchMorePartners } = useGetAllPartners({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const {
        partners: { edges, totalCount = 0 },
      } = data;
      const lastEdge = last(edges);
      

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }
      const partners = edges.map((r) => r.node);

      setAllPartners(partners);
      setIsLoadMoreDisabled(partners.length >= totalCount || totalCount <= pageSize);
      setTotalPartners(totalCount);
    },
  });

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    void handleFetchMorePartners({
      after: lastCursor,
      first: pageSize,
      filter,
    });
  };

  const handleFilterSearch = (value: string) => {
    setPartnersTableData([]);
    handleGetAllPartners({ first: pageSize, filter: value });
    setFilter(value);
  };


  useEffect(() => {
    handleGetAllPartners({ first: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!allPartners) {
      return;
    }

    setPartnersTableData(
      getPartnersTableData({
        partners: allPartners,
        onSettingsButtonClick: onDashboardButtonClick,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPartners]);

  return (
    <TablePageTemplate<IPartnersTableData>
      activePage="partners"
      breadcrumbItems={[{
        content: <Link href={`/management/springboard`}><span>Springboard</span></Link>,
        key: 'springboard',
      },
      {
        content: 'Partners',
        key: 'partners',
      }]}
      columns={PARTNERS_TABLE_COLUMNS}
      data={partnersTableData}
      description="Partners"
      handleOnPageSizeChange={handlePageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMorePartners({
          after: lastCursor,
          first: pageSize,
          filter,
        });
      }}
      handleSearch={handleFilterSearch}
      icon={ImageAssetIcon}
      informationBoxMessage="Manage active partners"
      initialSearchValue={filter}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      pageSize={pageSize}
      pageTitle="Partners"
      searchPlaceholder="Search"
      size="small"
      tableType="partners"
      title="Partners"
      totalItems={totalPartners}
      isManagementLevel
    />
  );
};
export { PartnersPage };
