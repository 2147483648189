import { gql } from '@apollo/client';

const GET_ALL_ORGANISATIONS = gql`
  query getAllOrganisations($after: String, $first: Int, $filter: String) {
    organisation {
      getAllOrganisations(after: $after, first: $first, filter: $filter) {
        totalCount
        edges {
          cursor
          node {
            id
            name
            key
          }
        }
      }
    }
  }
`;

export { GET_ALL_ORGANISATIONS };
