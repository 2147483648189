import { Avatar, Button, NewWindowIcon } from "@netfront/ui-library";
import { Column } from "react-table";

import { IPartnersTableData } from "./PartnersPage.interfaces";

const PARTNERS_TABLE_COLUMNS: ReadonlyArray<Column<IPartnersTableData>> = [
  {
    accessor: 'name',
    Cell: ({ value }) => (
      <div className="c-projects-table__projects-cell">
        <Avatar size="small" title={value} />
        {value}
      </div>
    ),
    Header: () => <div>Partner</div>,
    width: '90%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => (
      <div className="c-projects-table__projects-cell">
        <Button
          icon={NewWindowIcon}
          size="xs"
          text="Dashboard"
          variant="secondary"
          onClick={() => onClick(id)}
        ></Button>
      </div>
    ),
    width: '10%',
  },
];

export { PARTNERS_TABLE_COLUMNS };