import { useEffect, useState } from 'react';

import { IUser, useDomain, useIdentitySiteUrls, useUser } from '@netfront/gelada-identity-library';
import { Dropdown, EnterIcon, IDropdownNavigationItem, LogoAvatar, LogoutIcon, UserNavigation, UserNavigationItemProps } from '@netfront/ui-library';

const UserAccountMenu = () => {
  const { getUser } = useUser();
  // const loggedUser = getUser();

  const { isDomainReady } = useDomain();
  const { getLogoutUrl, getProfileUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);

  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [profileUrl, setProfileUrl] = useState<string>();
  const [fullName, setFullName] = useState<string>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();
  const [user, setUser] = useState<IUser>();
  const [footerConfig, setFooterConfig] = useState<IDropdownNavigationItem>();
  const [navigationList, setNavigationList] = useState<UserNavigationItemProps[]>([]);


  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    
    setLogoutUrl(getLogoutUrl());
    setProfileUrl(getProfileUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(user && profileUrl && logoutUrl)) {
      return;
    }

    const { firstName = '', lastName = '', email, pictures } = user;
    const { originalPicture, profilePicture } = pictures ?? {};

    const image = !profilePicture?.presignedUrl ? originalPicture?.presignedUrl ?? '' : profilePicture.presignedUrl;

    setFullName(`${String(firstName)} ${String(lastName)}`);
    setProfileImageUrl(String(decodeURIComponent(image)));

    const dropdownNavigationItems: UserNavigationItemProps[] = [
      {
        header: {
          title: `${String(firstName)} ${String(lastName)}`,
          name: String(email),
          icon: EnterIcon,
          href: String(profileUrl),
          linkText: 'View profile'
        },
        options: [],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    setFooterConfig({
      label: 'Logout',
      href: logoutUrl,
      icon: LogoutIcon,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileUrl, user, logoutUrl]);


  useEffect(() => {
    if (user) return;

    setUser(getUser());
  }, [getUser, user]);

  return (
    <div className="c-user-account-menu">
      {user && (
        <Dropdown
          dropdownId="profile-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<LogoAvatar avatarImageSrc={String(profileImageUrl)} size="small" title={String(fullName)} />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <UserNavigation
            dropdownNavigationItems={navigationList}
            footerConfig={footerConfig}
          />
        </Dropdown>
      )}
    </div>
  );
};

export { UserAccountMenu };
