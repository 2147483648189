import { useState } from 'react';

import { IDBPayout } from 'interfaces';

import { PayoutsPage, TransactionsPage } from './Components';


const PartnerManagementPayoutsPage = () => {
  const [selectedPayout, setSelectedPayout] = useState<IDBPayout>();

  const handleDisplayTransactionsTable = (payout?: IDBPayout) => {
    setSelectedPayout(payout);
  };


  if (!selectedPayout) return <PayoutsPage onDisplayTransactionsTable={handleDisplayTransactionsTable} />;

  return (
    <TransactionsPage
      selectedPayout={selectedPayout}
      selectedPayoutTransactions={selectedPayout.transactions}
      onDisplayTransactionsTable={handleDisplayTransactionsTable}
    />
  );
};

export { PartnerManagementPayoutsPage };
