/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useEffect, useState } from 'react';

export const useAppHeaderHeight = (): number => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    let retryInterval: NodeJS.Timeout | null = null;
    let observer: ResizeObserver | undefined;

    // Function to update header height in rem
    const updateHeaderHeight = () => {
      const header = document.querySelector('.c-app-header') as HTMLElement | null;
      if (header) {
        const heightInPixels = header.offsetHeight;
        const heightInRem = heightInPixels / 16;
        setHeaderHeight(heightInRem);

        // Clear retry interval once the header is found
        if (retryInterval) {
          clearInterval(retryInterval);
          retryInterval = null;
        }

        if (!observer) {
          observer = new ResizeObserver(() => {
            const updatedHeight = header.offsetHeight / 16;
            setHeaderHeight(updatedHeight);
          });
          observer.observe(header);
        }
      }
    };

    retryInterval = setInterval(() => {
      updateHeaderHeight();
    }, 500);

    // Perform an initial attempt
    updateHeaderHeight();

    // Clean up on component unmount
    return () => {
      if (retryInterval) {
        clearInterval(retryInterval);
      }
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return headerHeight;
};
