import { NewWindowIcon } from '@netfront/ui-library';
import Link from 'next/link';
import { Column } from 'react-table';

import { IReferralsTableData } from './PartnerManagementReferralsPage.interfaces';

const REFERRALS_TABLE_COLUMNS: ReadonlyArray<Column<IReferralsTableData>> = [
  {
    accessor: 'partnerLink',
    Cell: ({ value: { partnerId, name } }) => {
      return (
        <>
          {name}
          {partnerId && (
            <Link href="/partner/dashboard" rel="noopener noreferrer" target="_blank">
              <NewWindowIcon className="c-referrals-table__new-window-icon" />
            </Link>
          )}
        </>
      );
    },
    Header: () => <div>Project</div>,
  },
  {
    accessor: 'plan',
    Cell: ({ value: { description } }) => <>{description}</>,
    Header: () => <div>Plan</div>,
  },
  {
    accessor: 'createdAtUtc',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Referral date</div>,
  },
];

export { REFERRALS_TABLE_COLUMNS };
