import { useEffect, useState } from 'react';

import { Spinner } from '@netfront/ui-library';
import { IGetTamarinInsightsOnCompletedResponse, useGetTamarinInsights } from 'hooks';
import { DailyCount } from 'interfaces';

import { DailyCreation } from './DailyCreation/DailyCreation';
import { DailyScans } from './DailyScans/DailyScans';
import { QuickcodesInsightsProps } from './QuickcodesInsights.interfaces';

const QuickcodesInsights = ({ period }: QuickcodesInsightsProps) => {
  const [dailyScans, setDailyScans] = useState<DailyCount[]>([]);
  const [dailyCreation, setDailyCreation] = useState<DailyCount[]>([]);
  const onGetTamarinInsightsCompleted = (data: IGetTamarinInsightsOnCompletedResponse) => {
    const { dailyCreation: dc, dailyScans: ds } = data;
    setDailyCreation(dc);
    setDailyScans(ds);
  };
  const { handleGetTamarinInsights, isLoading = false } = useGetTamarinInsights({ onCompleted: onGetTamarinInsightsCompleted });

  useEffect(() => {
    handleGetTamarinInsights({
      end: period[1],
      start: period[0],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return (
    <>
      <Spinner isLoading={isLoading} />
      <DailyScans dailyScans={dailyScans} />
      <DailyCreation dailyCreation={dailyCreation} />
    </>
  );
};
export { QuickcodesInsights };
