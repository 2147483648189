import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_ALL_PARTNERS } from './useGetAllPartners.graphql';
import {
  IGetAllPartnersQueryGraphQLResponse,
  IGetAllPartnersQueryVariables,
  IHandleFetchMorePartnersParams,
  IUseGetAllPartners,
  IUseGetAllPartnersOptions,
} from './useGetAllPartners.interfaces';

const useGetAllPartners = (options?: IUseGetAllPartnersOptions): IUseGetAllPartners => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetAllPartnersOptions);

  const [executeGetAllPartners, { loading: isLoading, fetchMore }] = useLoggedGeladaLazyQuery<
  IGetAllPartnersQueryGraphQLResponse,
  IGetAllPartnersQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partner: { all: partners },
        } = data;

        onCompleted({
          partners,
        });
      },
      onError,
    },
    query: GET_ALL_PARTNERS,
    token,
  });

  const handleFetchMorePartners = ({
    after,
    first,
    filter,
  }: IHandleFetchMorePartnersParams): Promise<ApolloQueryResult<IGetAllPartnersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          partner: { all: newlyFetchedProjectConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedProjectConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          partner: { all: previouslyFetchedProjectConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedProjectConnection;

        return {
          ...previousQueryResult,
          project: {
            ...previouslyFetchedProjectConnection,
            getAll: {
              ...newlyFetchedProjectConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        filter,
      },
    });
  };

  const handleGetAllPartners = ({ after, filter, first }: IGetAllPartnersQueryVariables) => {
    void executeGetAllPartners({
      variables: {
        after,
        filter,
        first,
      },
    });
  };

  return {
    handleFetchMorePartners,
    handleGetAllPartners,
    isLoading,
  };
};

export { useGetAllPartners };
