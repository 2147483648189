import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PARTNER_QUERY } from './useGetPartner.graphql';
import {
  IGetPartnerQueryGraphQLResponse,
  IGetPartnerQueryVariables,
  IHandleGetPartnerParams,
  IUseGetPartner,
  IUseGetPartnerOptions,
} from './useGetPartner.interfaces';

const useGetPartner = (options?: IUseGetPartnerOptions): IUseGetPartner => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPartnerOptions);

  const [executeGetPartner, { loading: isLoading }] = useLoggedGeladaLazyQuery<IGetPartnerQueryGraphQLResponse, IGetPartnerQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partner: { get: partnerConnection },
        } = data;

        onCompleted({
          partnerConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PARTNER_QUERY,
    token,
  });

  const handleGetPartner = ({ after, first, id }: IHandleGetPartnerParams) => {
    void executeGetPartner({
      variables: {
        after,
        first,
        id,
      },
    });
  };

  return {
    handleGetPartner,
    isLoading,
  };
};

export { useGetPartner };
