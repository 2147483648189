import { ApolloError } from '@apollo/client';
import { useToast, useInvitePartnerUser, useAddOrUpdatePartnerTeam } from 'hooks';
import { DBPartnerUserPermissionType } from 'interfaces';



const useUpsertTeamUser = ({ onCompleted, product, token  }: { onCompleted: () => void; product?: "EKARDO" | "KANZI" | "QUICKCODES" | undefined; token?: string }) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };


  const { handleAddOrUpdatePartnerTeam, isLoading: isAddOrUpdatePartnerTeamLoading = false } = useAddOrUpdatePartnerTeam({
    onCompleted: () => {
      onCompleted();
      handleToastSuccess({
        message: 'Team member updated successfully',
      });
    },
    onError: handleGetError,
    token,
  });

  const { handleInvitePartnerUser, isLoading: isInvitePartnerUserLoading = false } = useInvitePartnerUser({
    onCompleted: () => {
      onCompleted();
      handleToastSuccess({
        message: 'The user has been invited',
      });

    },
    onError: handleGetError,
    token,
    product,
  });


  const handleUpsertTeamUser = ({
    email,
    firstName,
    lastName,
    partnerId,
    permission,
    userId,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    partnerId: number;
    permission: DBPartnerUserPermissionType;
    userId?: number;
  }) => {

    if (!userId) {
      handleInvitePartnerUser({
        input: {
          firstName,
          invitedEmail: email,
          lastName,
          permission,
          partnerId,
        },
      });
    } else {
      handleAddOrUpdatePartnerTeam({
        input: {
          partnerId: partnerId,
          permission,
          userId,
        },
      });
    }
  };

  return {
    isLoading: isAddOrUpdatePartnerTeamLoading || isInvitePartnerUserLoading,
    handleUpsertTeamUser,
  };
};
export { useUpsertTeamUser };
