import { useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { saveItemWithExpiryToLocalStorage, useCookie } from '@netfront/common-library';
import { CardListPageItems } from '@netfront/ui-library';
import { CardListTemplatePage } from 'components';
import { useGetPartnersForConnectedUser, useToast } from 'hooks';
import { IDBPartner } from 'interfaces';
import { useRouter } from 'next/router';


const DashboardPage = () => {
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const [partners, setPartners] = useState<IDBPartner[]>([]);

  const { handleGetPartnersForConnectedUser: executeGetPartnerForConnectedUser, isLoading: isGetPartnersForConnectedUserLoading = false } =
    useGetPartnersForConnectedUser({
      onCompleted: ({ partnersConnection: { edges } }) => {
        const allPartners = edges.map(({ node }) => node);

        const partnerCount = allPartners.length;
        //If the user doesn't have any partner, redirects to the login page
        if (partnerCount === 0) {
          void push('/login');
          return;
        }

        //If the user has only one partner, redirect straight to the partner dashboard
        if (partnerCount === 1) {
          
          const [partner] = allPartners;
          const { id } = partner;

          saveItemWithExpiryToLocalStorage('partner', String(id), {
            currentTimeValueInMilliseconds: new Date().getTime(),
            expiryTime: {
              unit: 'hours',
              value: 6,
            },
          });
          
          void push('/partner/dashboard');
          return;
        }

        setPartners(allPartners);
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
    });

  const handleClickPartner = (id: number) => {
    saveItemWithExpiryToLocalStorage('partner', String(id), {
      currentTimeValueInMilliseconds: new Date().getTime(),
      expiryTime: {
        unit: 'hours',
        value: 6,
      },
    });

    void push('/partner/dashboard');
  };

  useEffect(() => {
    void executeGetPartnerForConnectedUser({
      first: 100,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardListTemplatePage
      activePage=""
      additionalClassNames="c-card-list-page-template"
      breadcrumbItems={[]}
      defaultView="grid"
      description="Partners dashboard"
      hasSidebarNavigation={false}
      informationBoxMessage={"Partners dashboard"}
      isLoading={isGetPartnersForConnectedUserLoading}
      lists={[
        {
          isCentered: false,
          items: partners.map((item) => {
            const { id, name, logo } = item;
            const { presignedUrl = '' } = logo ?? {};
            return {
              type: 'nav',
              searchKey: name,
              itemProps: {
                key: String(id),
                id,
                image: {
                  altText: 'Partner',
                  src: presignedUrl,
                },
                imageSize: 'SMALL',
                supportiveText: name,
                onClick: () => handleClickPartner(id),
                title: name,
                isFavoriteVisible: false,
              },
            } as CardListPageItems;
          })
        }
      ]}
      listType="partners"
      pageTitle="Partners dashboard"
      title="Partners dashboard"
    />
  );
};

export { DashboardPage };
