import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { IGetGeladaProjectOnCompletedResponse, useGetGeladaProject } from '@netfront/gelada-identity-library';
import { ToggleSwitch , ImageAssetIcon, FlexContainer } from '@netfront/ui-library';
import { TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetProjectsByProduct } from 'hooks';
import { IDBMuriquiProject } from 'interfaces';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { MURIQUI_PROJECTS_TABLE_COLUMNS } from './MuriquiProjectsPage.constants';
import { getMuriquiProjectsTableData } from './MuriquiProjectsPage.helpers';
import { IMuriquiProjectsTableData, MuriquiProjectsPageProps } from './MuriquiProjectsPage.interfaces';

const MuriquiProjectsPage = ({ product }: MuriquiProjectsPageProps) => {
  const { dashboardUrl } = useContext(CachingEntitiesContext);

  const [totalProjects, setTotalProjects] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState<string>('');
  const [projectsTableData, setProjectsTableData] = useState<IMuriquiProjectsTableData[]>([]);
  const [lastCursor, setLastCursor] = useState<string>();
  const [allProjects, setAllProjects] = useState<IDBMuriquiProject[]>();
  const [isOnlyDisplayingPaidProject, setIsOnlyDisplayingPaidProject] = useState<boolean>(false);

  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);

  const { push } = useRouter();
  const onGetGeladaProjectResponse = (data: IGetGeladaProjectOnCompletedResponse) => {
    const {
      geladaProject: { organisationId, guid },
    } = data;
    const projectUrl = `${String(dashboardUrl)}/${organisationId}/${guid}`;
    void push(projectUrl);
  };

  const { handleGetGeladaProject } = useGetGeladaProject({ onCompleted: onGetGeladaProjectResponse });
  const { handleGetProjectsByProduct, isLoading = false, handleFetchMoreProjectsByProduct } = useGetProjectsByProduct({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const {
        projects: { edges, totalCount = 0},
      } = data;
      const lastEdge = last(edges);
      
      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }
      
      const projects = edges.map((r) => r.node);
      setIsLoadMoreDisabled(projects.length >= totalCount || totalCount <= pageSize);
      setTotalProjects(totalCount);
      setAllProjects(projects);
    },
  });



  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    void handleFetchMoreProjectsByProduct({
      after: lastCursor,
      first: pageSize,
      filter,
      product,
      isOnlyDisplayingPaidProject,
    });
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
    handleGetProjectsByProduct({ 
      first: pageSize, 
      filter: value, 
      product, 
      isOnlyDisplayingPaidProject 
    });

  };

  const onDashboardButtonClick = (id: string) => {
    void handleGetGeladaProject({ projectId: id });
  };

  const onOnlyDisplayPaidProjectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked },
    } = event;
    setIsOnlyDisplayingPaidProject(isChecked);
  };

  useEffect(() => {
    handleGetProjectsByProduct({ 
      first: pageSize, 
      product, 
      isOnlyDisplayingPaidProject,
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, isOnlyDisplayingPaidProject]);

  useEffect(() => {
    if (!allProjects) {
      return;
    }

    setProjectsTableData(
      getMuriquiProjectsTableData({
        projects: allProjects,
        onSettingsButtonClick: onDashboardButtonClick,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProjects]);
  


  return (
    <TablePageTemplate<IMuriquiProjectsTableData>
      activePage="projects"
      breadcrumbItems={[{
        content: <Link href={`/management/springboard/`}><span>Springboard</span></Link>,
        key: 'springboard',
      },
      {
        content: 'Projects',
        key: 'projects',
      }]}
      childrenEnd={(
        <FlexContainer alignItems="center">
          <ToggleSwitch
            additionalClassNames='c-page-toggle'
            id="display-paid"
            isChecked={isOnlyDisplayingPaidProject}
            labelText="Only display paid project"
            isInline
            onChange={onOnlyDisplayPaidProjectChange}
          />
        </FlexContainer>
      )}
      columns={MURIQUI_PROJECTS_TABLE_COLUMNS}
      data={projectsTableData}
      description="Projects"
      handleOnPageSizeChange={handlePageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMoreProjectsByProduct({
          after: lastCursor,
          first: pageSize,
          filter,
          product,
          isOnlyDisplayingPaidProject,
        });
      }}
      handleSearch={handleFilterSearch}
      icon={ImageAssetIcon}
      informationBoxMessage="Manage active projects"
      initialSearchValue={filter}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      pageSize={pageSize}
      pageTitle="Projects"
      searchPlaceholder="Search"
      size="small"
      tableType="projects"
      title="Projects"
      totalItems={totalProjects}
      isManagementLevel
    />
  );
};
export { MuriquiProjectsPage };
