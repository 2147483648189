import { gql } from '@apollo/client';

const ADD_OR_UPDATE_PARTNER_TEAM_MUTATION = gql`
  mutation AddOrUpdatePartnerTeam($input: AddOrUpdateInputGraphType!) {
    partnerUser {
      addOrUpdate(input: $input) {
        result
      }
    }
  }
`;

export { ADD_OR_UPDATE_PARTNER_TEAM_MUTATION };
