import { Input, Spacing } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import noop from 'lodash/noop';

import { TransactionGeneralTabProps } from './TransactionGeneralTab.interfaces';

const TransactionGeneralTab = ({ transaction }: TransactionGeneralTabProps) => {
  const { description, amount, status } = transaction;

  return (
    <SidebarContainer>
      <Spacing>
        <Input id="plan" labelText="Plan" name="plan" type="text" value={description} isDisabled isLabelSideBySide onChange={noop} />
      </Spacing>

      <Spacing>
        <Input id="amount" labelText="Amount" name="amount" type="text" value={amount} isDisabled isLabelSideBySide onChange={noop} />
      </Spacing>

      <Spacing>
        <Input
          id="project-details"
          labelText="Project details"
          name="project-details"
          type="text"
          value={status}
          isDisabled
          isLabelSideBySide
          onChange={noop}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { TransactionGeneralTab };
