import { useState } from 'react';

import { ChartPicker, AvailableChart, AvailableChartProps, IDoughnutFeed, DoughnutChartCard } from '@netfront/ui-library';

import { PartnerActiveProps } from './PartnerActive.interfaces';

const PartnerActive = ({ count }: PartnerActiveProps) => {
  const LABEL = 'Partners active';

  const [activeChart, setActiveChart] = useState<AvailableChart>('Doughnut');

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const DEFAULT_COLOR = '#972868';

  const doughnutData: IDoughnutFeed[] = [
    { backgroundColor: DEFAULT_COLOR, label: 'Active partners', value: count, borderColor: DEFAULT_COLOR },
  ];

  const availableCharts: AvailableChartProps[] = [
    { value: 'Doughnut', component: <DoughnutChartCard feed={doughnutData} label={LABEL} /> },
  ];

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_partner_active_chart_picker"
      onChange={onActiveChartChange}
    />
  );
};
export { PartnerActive };
