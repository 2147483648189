import { useState } from 'react';

import { LineChartCard, AvailableChart, ChartPicker, ILegend, AvailableChartProps, ILineChartDataset } from '@netfront/ui-library';

import { TranslateDailyUsageProps } from './TranslateDailyUsage.interfaces';


const TranslateDailyUsage = ({ translate }: TranslateDailyUsageProps) => {
  const LABEL = 'Translate';

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const PRIMARY_COLOR = '#972868';

  const lineChart: ILineChartDataset[] = [
    { backgroundColor: PRIMARY_COLOR, borderColor: PRIMARY_COLOR, data: translate.map((r) => r.count), label: 'Daily scans' },
  ];

  const legends: ILegend[] = [
    {
      color: PRIMARY_COLOR,
      label: 'Translate action',
    },
  ];

  const availableCharts: AvailableChartProps[] = [
    {
      value: 'Line',
      component: <LineChartCard datasets={lineChart} label={LABEL} labels={translate.map((r) => r.date.toString())} legends={legends} />,
    },
  ];

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_translate_daily_usage"
      onChange={onActiveChartChange}
    />
  );
};

export { TranslateDailyUsage };
