import { useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBAsset } from '@netfront/common-library';
import { useCreatePartnerLogoAsset, useToast } from 'hooks';
import { pushImageToAws } from 'utils';



const useUpdatePartnerLogoWrapper = ({ token }: { token?: string }) => {
  const uploadedFileRef = useRef<{value: File | undefined}>({value: undefined });
  const dynamicCallBackRef = useRef<((asset?: IDBAsset) => void) | null>(null);

  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);

  const { handleToastError } = useToast();

  const { handleCreatePartnerLogoAsset, isLoading: isCreatePartnerLogoAssetLoading = false } =
    useCreatePartnerLogoAsset({
      onCompletedAsync: async (data) => {
        const {  signedUrl } = data;
        setIsAssetUploadToAwsLoading(true);

        await pushImageToAws(signedUrl, uploadedFileRef.current.value, () => {
          setIsAssetUploadToAwsLoading(false);
  
          if (dynamicCallBackRef.current) {
            dynamicCallBackRef.current(data);
          }
      
          uploadedFileRef.current.value = undefined;
        });
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
    });


  const handleUpdatePartnerLogo = ({
    callBack,
    partnerId,
    uploadedFile,
  }: {
    callBack: (asset?: IDBAsset) => void;
    partnerId: number;
    uploadedFile?: File;
 
  }) => {

    dynamicCallBackRef.current = callBack;


    if (uploadedFile) {
      uploadedFileRef.current.value = uploadedFile;

      const { name, type, size } = uploadedFile;

      void handleCreatePartnerLogoAsset({
        input: {
          contentType: type,
          fileName: name,
          fileSizeInBytes: size,
          type: 'PARTNER_LOGO',
          partnerId,
        },
      });
      
    } else {

      callBack();
    }
  };

  return {
    isLoading: isCreatePartnerLogoAssetLoading || isAssetUploadToAwsLoading,
    handleUpdatePartnerLogo,
  };
};

export { useUpdatePartnerLogoWrapper };