import { useState } from 'react';

import { AvailableChartProps, BarChartCard, DoughnutChartCard, ChartPicker, AvailableChart, IBarChartDataset } from '@netfront/ui-library';

import { PaidProjectsProps } from './PaidProjects.interfaces';

const PaidProjects = ({ planUsages, totalCount }: PaidProjectsProps) => {
  const LABEL = 'Paid projects';

  const [activeChart, setActiveChart] = useState<AvailableChart>('Doughnut');

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const COLORS = ['#972868', '#4bc2ca', '#985dad', '#bded3e', '#ed633e'];

  const labels = planUsages.map((r) => r.name);

  const doughnutData = planUsages.map((r, i) => ({
    backgroundColor: COLORS[i],
    borderColor: COLORS[i],
    label: r.name,
    value: r.count,
  }));

  const barData: IBarChartDataset[] = [];
  planUsages.forEach((r, i) => {
    const data: number[] = [];
    for (let x = 0; x != i; x++) data.push(0);
    data.push(r.count);
    barData.push({
      backgroundColor: [COLORS[i]],
      label: 'Values',
      data: data,
      id: `id_${r.planId}`
    });
  });


  const legends = planUsages.map((r, i) => ({
    color: COLORS[i],
    label: r.name,
  }));

  const availableCharts: AvailableChartProps[] = [
    { value: 'Doughnut', component: <DoughnutChartCard feed={doughnutData} label={LABEL} /> },
    {
      value: 'Bar',
      component: <BarChartCard datasets={barData} displayedValue={String(totalCount)} label={LABEL} labels={labels} legends={legends} />,
    },
  ];

  return (
    <ChartPicker
      additionalClassNames="c-paid-project-chart"
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_paid_projects_chart_picker"
      onChange={onActiveChartChange}
    />
  );
};
export { PaidProjects };
