
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, RadioButtonGroup, Spacing } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { UpsertTeamGeneralTabProps } from './UpsertTeamGeneralTab.interfaces';

const UpsertTeamGeneralTab = ({ control, isReadOnly = false }: UpsertTeamGeneralTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>
      <Spacing size="2x-large">
        <Controller
          control={control}
          name="firstName"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_user_first_name"
              isDisabled={isReadOnly}
              labelText="First name"
              tooltipText="First name"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      
      <Spacing size="2x-large">
        <Controller
          control={control}
          name="lastName"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_user_last_name"
              isDisabled={isReadOnly}
              labelText="Last name"
              tooltipText="Last name"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      
      <Spacing size="2x-large">
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_user_email"
              isDisabled={isReadOnly}
              labelText="Email"
              tooltipText="Email"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      
      <Spacing size="2x-large">
        <Controller
          control={control}
          name="permission"
          render={({ field }) => (
            <RadioButtonGroup
              items={[
                {
                  id: 'View payouts',
                  labelText: 'View payouts',
                  value: 'VIEW_PAYOUTS',
                },
                {
                  id: 'View referrals',
                  labelText: 'View referrals',
                  value: 'VIEW_REFERRALS',
                },
                {
                  id: 'Manage team',
                  labelText: 'Manage team',
                  value: 'MANAGE_TEAM',
                },
                {
                  id: 'Manage settings',
                  labelText: 'Manage settings',
                  value: 'MANAGE_SETTINGS',
                },
                {
                  id: 'Access projects',
                  labelText: 'Access projects',
                  value: 'ACCESS_PROJECTS',
                },
              ]}
              selectedValue={field.value}
              title="Permissions"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { UpsertTeamGeneralTab };
