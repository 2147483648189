import { useState, useContext, useRef, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCookie } from '@netfront/common-library';
import { ControlledForm, FormFieldProps, GeneralTabIcon, SideBarTabSet, Spinner, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { UpsertTeamGeneralTab } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetProduct, useUpsertTeamUser } from 'hooks';
import { FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getTeamDefaultValues } from './UpsertTeamSidebarView.helpers';
import { UpsertTeamSidebarViewProps } from './UpsertTeamSidebarView.interfaces';

const UpsertTeamSidebarView = ({ 
  handleSideBarClose,
  selectedTeam,
  isSideBarOpen = false,
}: UpsertTeamSidebarViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();
  const { partner } = useContext(CachingEntitiesContext);
  const { getProduct } = useGetProduct();
  const product = getProduct();

  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});

  const selectedPartnerId = Number(partner?.id);

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required().label('First name'),
        lastName: yup.string().required().label('Last name'),
        email: yup.string().required().label('Email'),
        permission: yup.string().required().label('Permission'),
      }),
    ),
  });

  const { handleUpsertTeamUser, isLoading = false } = useUpsertTeamUser({
    onCompleted: () => {
      reset();
      handleSideBarClose();
    },
    product,
    token,
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  const handleSaveTeam = (item: FormFieldProps) => {
    const { 
      permission, 
      id,
      email,
      firstName,
      lastName,
    } = item;

    handleUpsertTeamUser({
      partnerId: selectedPartnerId,
      permission,
      email,
      firstName,
      lastName,
      userId: id,
    })
  };

  useEffect(() => {
    if (!isSideBarOpen) return;
    setDefaultValues(getTeamDefaultValues({ team: selectedTeam }));

  }, [isSideBarOpen, selectedTeam]);

  return (
    <ControlledForm
      callBack={(item: FormFieldProps) => {
        triggerTabsOnSuccessHandler();
        handleSaveTeam(item);
      }}
      handleSubmit={handleSubmit}
      onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
        triggerTabsOnErrorHandler(errs as FormFieldProps);
      }}
    >
      <Spinner isLoading={isLoading} />
      <SideBarTabSet
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={handleSideBarClose}
        hasViewPadding={false}
        isSideBarOpen={isSideBarOpen}
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () =>
              <UpsertTeamGeneralTab
                control={control}
                isReadOnly={Boolean(selectedTeam)}
              /> ,
          },
          
        ]}
        onSaveButtonType="submit"

      />
    </ControlledForm>
    
  );
};

export { UpsertTeamSidebarView };
