import { gql } from '@apollo/client';

const GET_ALL_PARTNERS = gql`
query getAllPartners($after:String,$first:Int,$filter:String){
  partner{
    all(after:$after,first:$first,filter:$filter){
      totalCount
      edges{
        cursor
        node{
          id
          name
        }
      }
    }
  }
}
`;

export { GET_ALL_PARTNERS };
