import { ApolloQueryResult } from '@apollo/client';

import { useMuriquiLazyQuery } from '../useMuriquiLazyQuery';

import { GET_BY_PRODUCT } from './useGetProjectsByProduct.graphql';
import {
  IGetProjectsByProductQueryGraphQLResponse,
  IGetProjectsByProductQueryVariables,
  IHandleFetchMoreProjectsByProductParams,
  IUseGetProjectsByProduct,
  IUseGetProjectsByProductOptions,
} from './useGetProjectsByProduct.interfaces';

const useGetProjectsByProduct = (options?: IUseGetProjectsByProductOptions): IUseGetProjectsByProduct => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetProjectsByProductOptions);

  const [executeGetProjectsByProduct, { loading: isLoading, fetchMore }] = useMuriquiLazyQuery<
  IGetProjectsByProductQueryGraphQLResponse,
  IGetProjectsByProductQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projects: { getByProduct },
        } = data;

        onCompleted({
          projects: getByProduct,
        });
      },
      onError,
    },
    query: GET_BY_PRODUCT,
    token,
  });

  const handleFetchMoreProjectsByProduct = ({
    after,
    first,
    filter,
  }: IHandleFetchMoreProjectsByProductParams): Promise<ApolloQueryResult<IGetProjectsByProductQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          projects: { getByProduct: newlyFetchedProjectConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedProjectConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          projects: { getByProduct: previouslyFetchedProjectConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedProjectConnection;

        return {
          ...previousQueryResult,
          projects: {
            ...previouslyFetchedProjectConnection,
            getByProduct: {
              ...newlyFetchedProjectConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        filter,
      },
    });
  };

  const handleGetProjectsByProduct = ({
    after,
    filter,
    first,
    product,
    isOnlyDisplayingPaidProject,
  }: IGetProjectsByProductQueryVariables) => {
    void executeGetProjectsByProduct({
      variables: {
        after,
        filter,
        first,
        isOnlyDisplayingPaidProject,
        product,
      },
    });
  };

  return {
    handleFetchMoreProjectsByProduct,
    handleGetProjectsByProduct,
    isLoading,
  };
};

export { useGetProjectsByProduct };
