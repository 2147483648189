import { useState, useEffect } from 'react';

import { GeneralTabIcon, SideBarTabSet } from '@netfront/ui-library';
import { TransactionGeneralTab } from 'components';
import { IDBInvoiceTransaction } from 'interfaces';

import { TransactionSidebarViewProps } from './TransactionSidebarView.interfaces';


const TransactionSidebarView = ({ 
  handleSideBarClose,
  selectedTransaction,
  isSideBarOpen = false,
}: TransactionSidebarViewProps) => {
  const [transaction, setTransaction] = useState<IDBInvoiceTransaction>();

  const handleClose = () => {
    setTransaction(undefined);
    handleSideBarClose();
  };
  
  useEffect(() => {
    if (!(isSideBarOpen && selectedTransaction)) return;

    setTransaction(selectedTransaction);
  }, [selectedTransaction, isSideBarOpen]);

  return (

    <SideBarTabSet
      defaultActiveTabId="id_general_tab"
      handleOpenCloseSideBar={handleClose}
      hasViewPadding={false}
      isSideBarOpen={isSideBarOpen}
      tabs={[
        {
          icon: GeneralTabIcon,
          id: 'id_general_tab',
          label: 'General',
          view: () => (
            <>
              {transaction && (
                <TransactionGeneralTab
                  transaction={transaction}
                />
              )}
            </>
          ),
        },
        
      ]}

    />
  );
};

export { TransactionSidebarView };
