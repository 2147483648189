import { FormFieldProps } from "@netfront/ui-library";
import { IDBPartner, IDBPartnerTheme } from "interfaces";

const getSettingsDefaultValues = ({ partner, partnerTheme }: { partner: IDBPartner; partnerTheme: IDBPartnerTheme}): FormFieldProps => {

  const {
    logo,
    address,
    phoneNumber,
    email,
    name,
  } = partner;

  const { id, presignedUrl = '' } = logo ?? {};

  const {
    brandAccentPrimary,
  } = partnerTheme

  return {
    brandColor: brandAccentPrimary,
    assetId: id,
    presignedUrl,
    address: address || '', 
    phoneNumber: phoneNumber || '', 
    email: email || '', 
    name: name || '', 
  }
};

export { getSettingsDefaultValues }; 