/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBGeladaProject } from "@netfront/gelada-identity-library";
import { FormFieldProps } from "@netfront/ui-library";

const getUpsertProjectDefaultValues = ({ project }: { project?: IDBGeladaProject}): FormFieldProps =>  {

  const {
    name = '', 
    description = '', 
    backOfficeUrl = '',
    logo,
  } = project ?? {};

  const { presignedUrl = '' } = logo ?? {};

  return {
    name: name || '',
    description: description || '',
    backOfficeUrl: backOfficeUrl || '',
    presignedUrl: presignedUrl || '',
  };
};


export { getUpsertProjectDefaultValues };