import { gql } from '@apollo/client';

const GET_GELADA_INSIGHTS = gql`
  query getInsights($start: DateTime!, $end: DateTime!, $product: EProduct!) {
    trend {
      insights(currentPeriodStart: $start, currentPeriodEnd: $end, product: $product) {
        projectsCreationDuringComparisonPeriod {
          count
          date
        }
        projectsCreationDuringSelectedPeriod {
          count
          date
        }
        activePartners
      }
    }
  }
`;

export { GET_GELADA_INSIGHTS };
