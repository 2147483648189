import { gql } from '@apollo/client';

const GET_PARTNERS_FOR_CONNECTED_USER_QUERY = gql`
  query getPartnersForConnectedUser($after: String, $first: Int) {
    partner {
      forConnectedUser {
        edges {
          node {
            id
            name
            code
            address
            email
            brandAccentPrimary
            logo {
              presignedUrl
            }
            phoneNumber
            organisations(after: $after, first: $first) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { GET_PARTNERS_FOR_CONNECTED_USER_QUERY };
