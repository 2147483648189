import { gql } from '@apollo/client';

const INVITE_USER_IN_PARTNER = gql`
  mutation partnerUserInvitation($input: PartnerInvitationInputType!) {
    invitation {
      partnerInvitation(invitation: $input) {
        id
      }
    }
  }
`;

export { INVITE_USER_IN_PARTNER };
