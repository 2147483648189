import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { ADD_OR_UPDATE_PARTNER_TEAM_MUTATION } from './useAddOrUpdatePartnerTeam.graphql';
import {
  IAddOrUpdatePartnerTeamMutationGraphQLResponse,
  IAddOrUpdatePartnerTeamMutationVariables,
  IHandleAddOrUpdatePartnerTeamParams,
  IUseAddOrUpdatePartnerTeamOptions,
  IUseAddOrUpdatePartnerTeam,
} from './useAddOrUpdatePartnerTeam.interfaces';

const useAddOrUpdatePartnerTeam = (options?: IUseAddOrUpdatePartnerTeamOptions): IUseAddOrUpdatePartnerTeam => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAddOrUpdatePartnerTeamOptions);

  const [executeAddOrUpdatePartnerTeam, { loading: isLoading }] = useLoggedGeladaMutation<
    IAddOrUpdatePartnerTeamMutationGraphQLResponse,
    IAddOrUpdatePartnerTeamMutationVariables
  >({
    mutation: mutation ?? ADD_OR_UPDATE_PARTNER_TEAM_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partnerUser: { addOrUpdatePartnerTeam: isCompleted },
        } = data;

        onCompleted({
          result: isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleAddOrUpdatePartnerTeam = ({ input }: IHandleAddOrUpdatePartnerTeamParams) => {
    void executeAddOrUpdatePartnerTeam({
      variables: {
        input,
      },
    });
  };

  return {
    handleAddOrUpdatePartnerTeam,
    isLoading,
  };
};

export { useAddOrUpdatePartnerTeam };
