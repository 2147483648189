import { useState, useContext, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset, useCookie, useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  Button,
  Input,
  Spacing,
  ColorPickerWrapper,
  RgbaColor,
  ImageUpload,
  ControlledForm,
  FormFieldProps,
  useControlledForm,
  FlexContainer,
} from '@netfront/ui-library';
import { BasicPageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useUpdatePartnerLogoWrapper, useUpdatePartnerWrapper } from 'hooks';
import { IDBPartnerAsset, IDBPartner } from 'interfaces';
import Link from 'next/link';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { getSettingsDefaultValues } from './PartnerManagementSettingsPage.helpers';


const PartnerManagementSettingsPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { partner, updatePartner: updatePartnerCtx, partnerTheme, updatePartnerTheme } = useContext(CachingEntitiesContext);

  const droppedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required().label('Business name'),
        email: yup.string().required().label('Email'),
        address: yup.string().required().label('Address'),
        phoneNumber: yup.string().required().label('Contact number'),
      }),
    ),
  });


  const { handleUpdatePartner, isLoading: isUpdatePartnerLoading = false } = useUpdatePartnerWrapper({
    token,
  });

  const { handleUpdatePartnerLogo, isLoading: isUpsertAssetLoading = false } = useUpdatePartnerLogoWrapper({
    token,
  });

  const handleColorChange = (updatedColor: RgbaColor) => {
    const { r, g, b, a = 0 } = updatedColor;

    const updatedColorRgba = `rgba(${r}, ${g}, ${b}, ${a})`;

    setValue('brandColor', updatedColorRgba);
  };

  const handleDropFile = (acceptedFile?: File) => {
    if (!partner) return;
    if (!acceptedFile) return;

    droppedFileRef.current.value = acceptedFile;

  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleSubmitBusinessDetails = (item: FormFieldProps) => {

    const {
      address,
      phoneNumber,
      email,
      name,
      brandColor,
    } = item;


    handleUpdatePartnerLogo({
      partnerId: Number(partner?.id),
      uploadedFile: droppedFileRef.current.value,
      callBack: (imageAsset?: IDBAsset) => {
        handleUpdatePartner({
          partnerInput: {
            address: address ?? '',
            phoneNumber: phoneNumber ?? '',
            email: email ?? '',
            name: name ?? '',
            partnerId: Number(partner?.id),
          },
          themeInput: {
            partnerId: Number(partner?.id),
            brandAccentPrimary: brandColor,
          },
          callBack: () => {
            updatePartnerCtx({
              ...partner,
              address,
              phoneNumber,
              email,
              name,
              organisations: partner?.organisations ?? [],
              logo: imageAsset,
              brandAccentPrimary: brandColor,
            } as IDBPartner);

            updatePartnerTheme({
              ...partnerTheme,
              presignedUrl: String(imageAsset?.presignedUrl),
              logo: (imageAsset ?? {}) as IDBPartnerAsset,
              brandAccentPrimary: brandColor,
            });

          }
        });
      }
    });
  };

  useEffect(() => {
    if (!(partner) ) {
      return;
    }

    setDefaultValues(getSettingsDefaultValues({ partner, partnerTheme }));
  }, [partner, partnerTheme]);


  const isLoading = isUpsertAssetLoading || isUpdatePartnerLoading;


  return (
    <BasicPageTemplate
      activePage="global-insights"
      breadcrumbItems={[{
        content: <Link href={`/partner/dashboard`}><span>Partners</span></Link>,
        key: 'partners',
        
      },
      {
        content: 'Settings',
        key: 'settings',
      }]}
      description="Partner settings"
      informationBoxMessage="Manage your business and payout information"
      pageTitle="Partner management settings"
      title="Settings"
    >
      <>
        <ControlledForm
          callBack={(item: FormFieldProps) => {
            handleSubmitBusinessDetails(item);
          }}
          handleSubmit={handleSubmit}
          isStopPropagation
        >
          <Spacing>
            <span className="c-settings-page__form-section-title">Business details</span>
          </Spacing>

          <FlexContainer alignItems="center" gap="x-large">
            <div className="c-settings-page__form-field">
              <Spacing>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState }) => (
                    <Input
                      errorMessage={getFormFieldErrorMessage(fieldState)}
                      id="id_business_name"
                      labelText="Business name"
                      type="text"
                      isRequired
                      {...field}
                    />
                  )}
                />
              </Spacing>
            </div>
            <div className="c-settings-page__form-field">
              <Spacing>
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <Input
                      errorMessage={getFormFieldErrorMessage(fieldState)}
                      id="id_business_email"
                      labelText="Email"
                      type="text"
                      isRequired
                      {...field}
                    />
                  )}
                />
              </Spacing>
            </div>
          </FlexContainer>
          
          <FlexContainer alignItems="center" gap="x-large">
            <div className="c-settings-page__form-field">
              <Spacing>
                <Controller
                  control={control}
                  name="address"
                  render={({ field, fieldState }) => (
                  
                    <Input
                      errorMessage={getFormFieldErrorMessage(fieldState)}
                      id="id_business_address"
                      labelText="Address"
                      type="text"
                      isRequired
                      {...field}
                    />
                  )}
                />
              </Spacing>
            </div>
            <div className="c-settings-page__form-field">
              <Spacing>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field, fieldState }) => (
                    <Input
                      errorMessage={getFormFieldErrorMessage(fieldState)}
                      id="id_business_contact_number"
                      labelText="Contact number"
                      type="text"
                      isRequired
                      {...field}
                    />
                  )}
                />
              </Spacing>
            </div>
          </FlexContainer>
          

          <Spacing>
            <span className="c-settings-page__form-section-title">Branding</span>
          </Spacing>

          {partner && (
            <div className="c-settings-page__form-field">
              <Spacing>
                <Controller
                  control={control}
                  name="presignedUrl"
                  render={({ field }) => (
                    <ImageUpload
                      id="id_partner_logo"
                      initialUrl={field.value}
                      isLoading={isLoading}
                      labelText="Upload partner logo"
                      name="logoUrl"
                      tooltipText="Upload your logo"
                      onDelete={handleRemoveAsset}
                      onDrop={handleDropFile}
                    />
                  )}
                />
              </Spacing>
            </div>
          )}
        
          <div className="c-settings-page__color-picker">
            <Spacing>
              <Controller
                control={control}
                name="brandColor"
                render={({ field }) => (
                  <ColorPickerWrapper
                    color={field.value}
                    id="id_brand_color"
                    label="Configure branding color"
                    pickerPosition="left"
                    type="rgba"
                    onRgbaChange={handleColorChange}
                  />
                )}
              />
            </Spacing>
          </div>
          <FlexContainer justifyContent="flex-end">
            <Button size="xs" text="Update" type="submit"/>
          </FlexContainer>
        </ControlledForm>
      </>
    </BasicPageTemplate>
  );
};

export { PartnerManagementSettingsPage };
