import { ApolloQueryResult } from '@apollo/client';

import { useMuriquiLazyQuery } from '../useMuriquiLazyQuery';

import { GET_PAYOUTS } from './useGetPayouts.graphql';
import {
  IGetPayoutsQueryGraphQLResponse,
  IGetPayoutsQueryVariables,
  IHandleFetchMorePayoutsParams,
  IHandleGetPayoutsParams,
  IUseGetPayouts,
  IUseGetPayoutsOptions,
} from './useGetPayouts.interfaces';

const useGetPayouts = (options?: IUseGetPayoutsOptions): IUseGetPayouts => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetPayoutsOptions);

  const [executeGetPayouts, { fetchMore, loading: isLoading }] = useMuriquiLazyQuery<
  IGetPayoutsQueryGraphQLResponse,
  IGetPayoutsQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partners: { payouts: partnerPayoutsConnection },
        } = data;

        onCompleted({
          partnerPayoutsConnection,
        });
      },
      onError,
    },
    query: GET_PAYOUTS,
    token,
  });

  const handleFetchMorePayouts = ({
    after,
    first,
    partnerId,
  }: IHandleFetchMorePayoutsParams): Promise<ApolloQueryResult<IGetPayoutsQueryGraphQLResponse>> => {
    return fetchMore({
      // TODO: Fix this one
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          partners: { payouts: newlyFetchedPayoutsConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedPayoutsConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          partners: { payouts: previouslyFetchedPayoutsConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedPayoutsConnection;

        return {
          ...previousQueryResult,
          partners: {
            ...previouslyFetchedPayoutsConnection,
            Payouts: {
              ...newlyFetchedPayoutsConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        partnerId,
      },
    });
  };

  const handleGetPayouts = ({ after, first, partnerId, end, product, start }: IHandleGetPayoutsParams) => {
    void executeGetPayouts({
      variables: {
        after,
        first,
        partnerId,
        end,
        product,
        start,
      },
    });
  };

  return {
    handleFetchMorePayouts,
    handleGetPayouts,
    isLoading,
  };
};

export { useGetPayouts };
