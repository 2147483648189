import { useEffect, useState } from 'react';

import { Spinner } from '@netfront/ui-library';
import {
  IGetGeladaInsightsOnCompletedResponse,
  IGetMuriquiInsightsOnCompletedResponse,
  IGetProjectsByProductOnCompletedResponse,
  useGetGeladaInsights,
  useGetMuriquiInsights,
  useGetProjectsByProduct,
} from 'hooks';
import { DailyCount, PlanUsage } from 'interfaces';

import { ActiveProjects } from './ActiveProjects/ActiveProjects';
import { GenericInsightsProps } from './GenericInsights.interfaces';
import { PaidProjects } from './PaidProjects/PaidProjects';
import { PartnerActive } from './PartnerActive/PartnerActive';
import { TotalProjects } from './TotalProjects/TotalProjects';

const GenericInsights = ({ isTimeBound = false, period, product }: GenericInsightsProps) => {
  const [activePartners, setActivePartners] = useState<number>(0);
  const [projectsCreationDuringComparisonPeriod, setProjectsCreationDuringComparisonPeriod] = useState<DailyCount[]>([]);
  const [projectsCreationDuringSelectedPeriod, setProjectsCreationDuringSelectedPeriod] = useState<DailyCount[]>([]);
  const [payingProjectCount, setPayingProjectCount] = useState<number>(0);
  const [totalProjects, setTotalProjects] = useState<number>(0);
  const [planUsages, setPlanUsages] = useState<PlanUsage[]>([]);

  const onGetGeladaInsightsCompleted = (data: IGetGeladaInsightsOnCompletedResponse) => {
    const { activePartners: ap, projectsCreationDuringComparisonPeriod: comparison, projectsCreationDuringSelectedPeriod: selected } = data;
    setActivePartners(ap);
    setProjectsCreationDuringComparisonPeriod(comparison);
    setProjectsCreationDuringSelectedPeriod(selected);
  };

  const onGetMuriquiInsightsCompleted = (data: IGetMuriquiInsightsOnCompletedResponse) => {
    const { payingProject, usages } = data;
    setPlanUsages(usages);
    setPayingProjectCount(payingProject);
  };

  const onGetMuriquiProjectCompleted = (data: IGetProjectsByProductOnCompletedResponse) => {
    const {
      projects: { totalCount = 0},
    } = data;

    setTotalProjects(totalCount);
  };

  const { handleGetGeladaInsights, isLoading: isGetGeladaInsightsLoading = false } = useGetGeladaInsights({ onCompleted: onGetGeladaInsightsCompleted });
  const { handleGetMuriquiInsights, isLoading: isGetMuriquiInsightsLoading = false } = useGetMuriquiInsights({ onCompleted: onGetMuriquiInsightsCompleted });
  const { handleGetProjectsByProduct, isLoading: isGetMuriquiProjectsLoading = false } = useGetProjectsByProduct({ onCompleted: onGetMuriquiProjectCompleted});

  useEffect(() => {

    if (isTimeBound) {
      handleGetGeladaInsights({
        end: period[1],
        product,
        start: period[0],
      });
    } else {
      handleGetMuriquiInsights({
        end: period[1],
        product,
        start: period[0],
      });

      handleGetProjectsByProduct({
        product,
        isOnlyDisplayingPaidProject: false,
      });
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, isTimeBound]);

  const isLoading = isGetGeladaInsightsLoading || isGetMuriquiInsightsLoading || isGetMuriquiProjectsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      {isTimeBound && (
        <>
          <PartnerActive count={activePartners} />
          <TotalProjects
            comparisonPeriodDailyCount={projectsCreationDuringComparisonPeriod}
            selectedPeriodDailyCount={projectsCreationDuringSelectedPeriod}
          />
        </>
      )}
      {!isTimeBound && (
        <>
          <PaidProjects planUsages={planUsages} totalCount={payingProjectCount} />
          <ActiveProjects totalCount={totalProjects} />
        </>
      )}
      
    </>
  );
};
export { GenericInsights };
