import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_PROJECT_LOGO_MUTATION } from './useUpdateProjectLogo.graphql';
import {
  IUpdateProjectLogoMutationGraphQLResponse,
  IUpdateProjectLogoMutationVariables,
  IHandleUpdateProjectLogoParams,
  IUseUpdateProjectLogoOptions,
  IUseUpdateProjectLogo,
} from './useUpdateProjectLogo.interfaces';

const useUpdateProjectLogo = (options?: IUseUpdateProjectLogoOptions): IUseUpdateProjectLogo => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateProjectLogoOptions);

  const [executeUpdateProjectLogo, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateProjectLogoMutationGraphQLResponse,
    IUpdateProjectLogoMutationVariables
  >({
    mutation: mutation ?? UPDATE_PROJECT_LOGO_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: {
            updateLogo: {
              logo: { presignedUrl },
            },
          },
        } = data;

        void onCompleted({ presignedUrl });
      },
      onError,
    },
    token,
  });

  const handleUpdateProjectLogo = ({ id, projectId }: IHandleUpdateProjectLogoParams) => {
    void executeUpdateProjectLogo({
      variables: {
        projectId,
        id,
      },
    });
  };

  return {
    handleUpdateProjectLogo,
    isLoading,
  };
};

export { useUpdateProjectLogo };
