import { useState } from 'react';

import { AvailableChart, ChartPicker, LineChartCard, AvailableChartProps, ILegend, ILineChartDataset } from '@netfront/ui-library';

import { DailyCreationProps } from './DailyCreation.interfaces';

const DailyCreation = ({ dailyCreation }: DailyCreationProps) => {
  const LABEL = 'Codes created';

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const PRIMARY_COLOR = '#972868';

  const labels = dailyCreation.map((r) => r.date.toString());

  const lineChart: ILineChartDataset[] = [
    { backgroundColor: PRIMARY_COLOR, borderColor: PRIMARY_COLOR, data: dailyCreation.map((r) => r.count), label: 'Daily scans' },
  ];

  const legends: ILegend[] = [
    {
      color: PRIMARY_COLOR,
      label: 'Daily scans',
    },
  ];

  const availableCharts: AvailableChartProps[] = [
    { value: 'Line', component: <LineChartCard datasets={lineChart} label={LABEL} labels={labels} legends={legends} /> },
  ];

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_daily_creation_chart_picker"
      onChange={onActiveChartChange}
    />
  );
};

export { DailyCreation };
