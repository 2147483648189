import { useState, useContext, useRef, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCookie } from '@netfront/common-library';
import { useCreateGeladaOrganisation } from '@netfront/gelada-identity-library';
import { ControlledForm, FormFieldProps, GeneralTabIcon, SideBarTabSet, Spinner, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { CreateOrganisationGeneralTab } from 'components';
import { CachingEntitiesContext } from 'context';
import { useLinkOrganisation, useToast } from 'hooks';
import kebabCase from 'lodash.kebabcase';
import { FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { CreateOrganisationSidebarViewProps } from './CreateOrganisationSidebarView.interfaces';

const CreateOrganisationSidebarView = ({ 
  handleSideBarClose,
  isSideBarOpen = false,
  onCreated,
  onLinked,
}: CreateOrganisationSidebarViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();
  const { partner } = useContext(CachingEntitiesContext);

  const { handleToastError } = useToast();

  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required().label('Name'),
      }),
    ),
  });

  const { handleCreateGeladaOrganisation, isLoading: isCreateGeladaOrganisationLoading = false } = useCreateGeladaOrganisation({
    onCompleted: ({ organisation: returnedOrganisation }) => {
      onCreated(returnedOrganisation);
      reset();

      handleLinkOrganisation({
        input: {
          organisationId: returnedOrganisation.id,
          partnerId: Number(partner?.id),
        },
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const { handleLinkOrganisation, isLoading: isLinkOrganisationLoading = false } = useLinkOrganisation({
    onCompleted: ({ result }) => {
      onLinked(result);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  const handleSaveTeam = (item: FormFieldProps) => {
    const { name } = item;

    void handleCreateGeladaOrganisation({
      name: String(name),
      description: '',
      key: kebabCase(String(name))
    });
  };

  useEffect(() => {
    if (!isSideBarOpen) return;
    setDefaultValues({
      name: '',
    });

  }, [isSideBarOpen]);

  return (
    <ControlledForm
      callBack={(item: FormFieldProps) => {
        triggerTabsOnSuccessHandler();
        handleSaveTeam(item);
      }}
      handleSubmit={handleSubmit}
      onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
        triggerTabsOnErrorHandler(errs as FormFieldProps);
      }}
    >
      <Spinner isLoading={isCreateGeladaOrganisationLoading || isLinkOrganisationLoading} />
      <SideBarTabSet
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={handleSideBarClose}
        hasViewPadding={false}
        isSideBarOpen={isSideBarOpen}
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => <CreateOrganisationGeneralTab control={control}/>,
          },
          
        ]}
        onSaveButtonType="submit"

      />
    </ControlledForm>
    
  );
};

export { CreateOrganisationSidebarView };
