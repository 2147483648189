import { useEffect, useState } from 'react';

import { Spinner } from '@netfront/ui-library';
import { IGetKanziInsightsOnCompletedResponse, useGetKanziInsights } from 'hooks';
import { DailyCount } from 'interfaces';

import { KanziInsightsProps } from './KanziInsights.interfaces';
import { PdfDailyUsage } from './PdfDailyUsage/PdfDailyUsage';
import { SpeechDailyUsage } from './SpeechDailyUsage/SpeechDailyUsage';
import { TranslateDailyUsage } from './TranslateDailyUsage/TranslateDailyUsage';

const KanziInsights = ({ period }: KanziInsightsProps) => {
  const [pdfDailyCount, setPdfDailyCount] = useState<DailyCount[]>([]);
  const [translateDailyCount, setTranslateDailyCount] = useState<DailyCount[]>([]);
  const [speechDailyCount, setSpeechDailyCount] = useState<DailyCount[]>([]);

  const onGetKanziInsightsCompleted = (data: IGetKanziInsightsOnCompletedResponse) => {
    const { pdfs, speeches, translate } = data;
    setTranslateDailyCount(translate);
    setSpeechDailyCount(speeches);
    setPdfDailyCount(pdfs);
  };
  const { handleGetKanziInsights, isLoading = false } = useGetKanziInsights({ onCompleted: onGetKanziInsightsCompleted });

  useEffect(() => {
    handleGetKanziInsights({
      end: period[1],
      start: period[0],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return (
    <>
      <Spinner isLoading={isLoading} />
      <PdfDailyUsage pdfs={pdfDailyCount} />
      <TranslateDailyUsage translate={translateDailyCount} />
      <SpeechDailyUsage speeches={speechDailyCount} />
    </>
  );
};
export { KanziInsights };
