import { gql } from '@apollo/client';

const GET_PARTNER_QUERY = gql`
  query GetPartner($id: Int!, $after: String, $first: Int) {
    partner {
      get(id: $id) {
        name
        phoneNumber
        email
        address
        brandAccentPrimary
        logo {
          presignedUrl
        }
        id
        code
        organisations(after: $after, first: $first) {
          edges {
            node {
              id
              key
              name
            }
          }
        }
      }
    }
  }
`;

export { GET_PARTNER_QUERY };
