import { LOCALHOST, PROTOCOLS, useDomain } from '@netfront/gelada-identity-library';

import { USE_PRODUCTS_SITE_URLS_CONSTANTS } from './useProductsSiteUrls.constants';
import { useProductsSiteUrlsOptions, IUseProductsSiteUrlsUrls } from './useProductsSiteUrls.interfaces';

const useProductsSiteUrls = (options?: useProductsSiteUrlsOptions): IUseProductsSiteUrlsUrls => {
  const { environment, port } = options ?? {};

  const { HTTP, HTTPS } = PROTOCOLS;
  const {
    ROUTES: { DASHBOARD },
  } = USE_PRODUCTS_SITE_URLS_CONSTANTS;

  const { getDomain } = useDomain();

  const getBaseUrl = () => {
    const domain = getDomain();

    if (domain === LOCALHOST) {
      const portSuffix = port ? `:${port}` : '';

      return `${HTTP}${domain}${portSuffix}`;
    }

    const environmentPrefix = environment ? `${environment}-.` : '';

    return `${HTTPS}${environmentPrefix}${domain}`;
  };

  const getDashboardRoute = () => {
    return DASHBOARD;
  };

  const getDashboardUrl = () => {
    return `${getBaseUrl()}${getDashboardRoute()}`;
  };

  return {
    getBaseUrl,
    getDashboardRoute,
    getDashboardUrl,
  };
};

export { useProductsSiteUrls };
