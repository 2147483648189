import { useEffect } from 'react';

import { useAuthentication } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { useToast } from '../useToast/useToast';

import { IUseProtectedRoute } from './useProtectedRoute.interfaces';

const useProtectedRoute = (): IUseProtectedRoute => {
  const { isAuthenticated } = useAuthentication();

  const { push } = useRouter();
  const { handleToastError } = useToast();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    const {
      location: { pathname: requestedRoute },
    } = window;

    push(`/login?requestedRoute=${requestedRoute}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return {
    isAuthenticated,
  };
};

export { useProtectedRoute };
