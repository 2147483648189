import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_TREND } from './useGetTrend.graphql';
import {
  IGetTrendQueryGraphQLResponse,
  IGetTrendQueryVariables,
  IHandleGetTrendParams,
  IUseGetTrend,
  IUseGetTrendOptions,
} from './useGetTrend.interfaces';

const useGetTrend = (options?: IUseGetTrendOptions): IUseGetTrend => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetTrendOptions);

  const [executeGetTrend, { loading: isLoading }] = useLoggedGeladaLazyQuery<IGetTrendQueryGraphQLResponse, IGetTrendQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          trend: {
            get: { organisationCount, organisationTrend, partnerCount, partnerTrend, projectCount, projectTrend },
          },
        } = data;

        onCompleted({
          organisationCount,
          organisationTrend,
          partnerCount,
          partnerTrend,
          projectCount,
          projectTrend,
        });
      },
      onError,
    },
    query: GET_TREND,
    token,
  });

  const handleGetTrend = () => {
    void executeGetTrend({});
  };

  return {
    handleGetTrend,
    isLoading,
  };
};

export { useGetTrend };
