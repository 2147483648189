import { useKanziLazyQuery } from 'hooks/apollo/useKanziLazyQuery';

import { GET_KANZI_INSIGHTS } from './useGetKanziInsights.graphql';
import {
  IGetKanziInsightsQueryGraphQLResponse,
  IGetKanziInsightsQueryVariables,
  IHandleGetKanziInsightsParams,
  IUseGetKanziInsights,
  IUseGetKanziInsightsOptions,
} from './useGetKanziInsights.interfaces';

const useGetKanziInsights = (options?: IUseGetKanziInsightsOptions): IUseGetKanziInsights => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetKanziInsightsOptions);

  const [executeGetKanziInsights, { loading: isLoading }] = useKanziLazyQuery<
    IGetKanziInsightsQueryGraphQLResponse,
    IGetKanziInsightsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          report: { insights },
        } = data;

        onCompleted(insights);
      },
      onError,
    },
    query: GET_KANZI_INSIGHTS,
    token,
  });

  const handleGetKanziInsights = ({ end, start }: IHandleGetKanziInsightsParams) => {
    void executeGetKanziInsights({
      variables: {
        end,
        start,
      },
    });
  };

  return {
    handleGetKanziInsights,
    isLoading,
  };
};

export { useGetKanziInsights };
