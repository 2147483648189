import { IGetPartnersTableDataParams, IPartnersTableData } from './PartnersPage.interfaces';

const getPartnersTableData = ({ partners, onSettingsButtonClick }: IGetPartnersTableDataParams): IPartnersTableData[] => {
  return partners.map(({ id, name }): IPartnersTableData => {
    return {
      name,
      id,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      }
    };
  });
};

export { getPartnersTableData };
