import { gql } from '@apollo/client';

const LINK_ORGANISATION_MUTATION = gql`
  mutation LinkOrganisation($input: LinkOrganisationInputGraphType!) {
    partner {
      linkOrganisation(input: $input) {
        result
      }
    }
  }
`;

export { LINK_ORGANISATION_MUTATION };
