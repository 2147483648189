import { gql } from '@apollo/client';

const GET_MURIQUI_INSIGHTS = gql`
  query insights($start: DateTime!, $end: DateTime!, $product: EProduct!) {
    insights {
      insights(start: $start, end: $end, product: $product) {
        payingProject
        usages {
          planId
          name
          count
        }
      }
    }
  }
`;

export { GET_MURIQUI_INSIGHTS };
