import { useState, useRef, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCookie } from '@netfront/common-library';
import { ControlledForm, FormFieldProps, GeneralTabIcon, SideBarTabSet, Spinner, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { UpsertProjectGeneralTab } from 'components';
import { useCreateProjectLogoWrapper, useUpsertProject } from 'hooks';
import { FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getUpsertProjectDefaultValues } from './UpsertProjectSidebarView.helpers';
import { UpsertProjectSidebarViewPropsProps } from './UpsertProjectSidebarView.interfaces';

const UpsertProjectSidebarView = ({ 
  handleSideBarClose,
  isSideBarOpen = false,
  organisationId,
  project,
  onUpsert,
}: UpsertProjectSidebarViewPropsProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const droppedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required().label('Name'),
      }),
    ),
  });

  const { handleCreateProjectLogo, isLoading: isCreateProjectLogoLoading = false } = useCreateProjectLogoWrapper({
    token,
  });

  const { handleUpsertProject, isLoading: isUpsertProjectLoading = false } = useUpsertProject({
    onUpsert: (returnedProject) => {
      onUpsert(returnedProject);
      droppedFileRef.current.value = undefined;
      reset();
    },
    token,
  });

  const handleDropFile = (acceptedFile?: File) => {
    droppedFileRef.current.value = acceptedFile;
  };


  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  const handleSave = (item: FormFieldProps) => {
    const { name, description = '', backOfficeUrl = '', } = item;

    handleCreateProjectLogo({
      projectId: project?.id,
      uploadedFile: droppedFileRef.current.value,
      callBack: () => {
        void handleUpsertProject({
          name: String(name),
          description,
          backOfficeUrl,
          projectId: project?.id,
          organisationId,
        });
      }
    });

    
  };

  useEffect(() => {
    if (!isSideBarOpen) return;
    setDefaultValues(getUpsertProjectDefaultValues({ project }));

  }, [isSideBarOpen, project]);

  return (
    <ControlledForm
      callBack={(item: FormFieldProps) => {
        triggerTabsOnSuccessHandler();
        handleSave(item);
      }}
      handleSubmit={handleSubmit}
      onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
        triggerTabsOnErrorHandler(errs as FormFieldProps);
      }}
    >
      <Spinner isLoading={isUpsertProjectLoading || isCreateProjectLogoLoading} />
      <SideBarTabSet
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={handleSideBarClose}
        hasViewPadding={false}
        isSideBarOpen={isSideBarOpen}
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => <UpsertProjectGeneralTab control={control} isUpdate={Boolean(project)} onDrop={handleDropFile} />,
          },
        ]}
        onSaveButtonType="submit"

      />
    </ControlledForm>
    
  );
};

export { UpsertProjectSidebarView };
