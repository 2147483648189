import format from 'date-fns/format';
import { IDBCustomerDetail } from 'interfaces';

import { PRODUCT_PREFIXES } from './MuriquiProjectsPage.constants';
import { IGetMuriquiProjectsTableDataParams, IMuriquiProjectsTableData } from './MuriquiProjectsPage.interfaces';

const hasAnyPaidPlan = (customerDetail: IDBCustomerDetail) => {
  const { subscriptions } = customerDetail;
  if (subscriptions.length === 0) return false;
  return subscriptions.some((r) => !r.plan.isFree);
};

const getPlanNames = (customerDetail: IDBCustomerDetail): string[] => {
  const { subscriptions } = customerDetail;

  if (subscriptions.length === 0) return ['Free'];

  const plans = subscriptions.map(({ plan }) => plan);

  const planNames = plans.reduce((acc, plan) => {
    const { productId, name } = plan;
    const prefix = PRODUCT_PREFIXES[productId] || '';
    acc.push(`${prefix}${name}`.trim());
    return acc;
  }, [] as string[]);

  return planNames;
};

const getLastDayOfMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

const getRenewalDate = (subscriptionDate?: Date): string => {
  if (!subscriptionDate || isNaN(new Date(subscriptionDate).getTime())) {
    return '';
  }
    
  const today = new Date();
  const subscriptionDay = new Date(subscriptionDate).getDate();
  const todayDay = today.getDate();

  // Determine if renewal is this month or next month
  const targetMonth = todayDay >= subscriptionDay ? today.getMonth() + 1 : today.getMonth();
  const lastDayOfTargetMonth = getLastDayOfMonth(today.getFullYear(), targetMonth);

  // Create endDate, adjusting for month length
  const endDate = new Date(
    today.getFullYear(), 
    targetMonth, 
    Math.min(subscriptionDay, lastDayOfTargetMonth)
  );

  return format(endDate, 'do MMMM');
};

const getMuriquiProjectsTableData = ({ projects, onSettingsButtonClick }: IGetMuriquiProjectsTableDataParams): IMuriquiProjectsTableData[] => {
  return projects.map((project): IMuriquiProjectsTableData => {

    const { id, name, customerDetail } = project;

    const { subscriptionDate } = customerDetail;

    let formattedRenewalDate = '';

    if (subscriptionDate) {
      formattedRenewalDate = getRenewalDate(new Date(subscriptionDate));
    }
    return {
      name,
      id,
      plans: getPlanNames(customerDetail),
      subscriptionRenewal: formattedRenewalDate,
      settingsButtonData: {
        id,
        isPaid: hasAnyPaidPlan(customerDetail),
        onClick: onSettingsButtonClick,
      },
    };
  });
};

const TOOLTIP_CONSTANTS = Object.freeze({
  PAID: 'The project has at least one paid subscription on the product',
  UNPAID: 'The project is using the free tier subscription on the product',
});

export { getMuriquiProjectsTableData, TOOLTIP_CONSTANTS };
