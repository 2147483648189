import { gql } from '@apollo/client';

const GET_INVOICE_TRANSACTIONS_QUERY = gql`
  query GetInvoiceTransactions {
    transactions {
      getInvoiceTransactions {
        billingAddress
        countryCode
        description
        id
        order {
          status
        }
        paymentProviderType
        primaryContactName
        status
        totalAmountIncTaxInDollars
      }
    }
  }
`;

export { GET_INVOICE_TRANSACTIONS_QUERY };
