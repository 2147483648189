import { ApolloQueryResult } from '@apollo/client';

import { useMuriquiLazyQuery } from '../useMuriquiLazyQuery';

import { GET_REFERRAL_PROJECTS_QUERY } from './useGetReferralProjects.graphql';
import {
  IGetReferralProjectsQueryGraphQLResponse,
  IGetReferralProjectsQueryVariables,
  IHandleFetchMoreReferralProjectsParams,
  IHandleGetReferralProjectsParams,
  IUseGetReferralProjects,
  IUseGetReferralProjectsOptions,
} from './useGetReferralProjects.interfaces';

const useGetReferralProjects = (options?: IUseGetReferralProjectsOptions): IUseGetReferralProjects => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetReferralProjectsOptions);

  const [executeGetReferralProjects, { fetchMore, loading: isLoading }] = useMuriquiLazyQuery<
  IGetReferralProjectsQueryGraphQLResponse,
  IGetReferralProjectsQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partners: { referralProjects: projectReferralsConnection },
        } = data;

        onCompleted({
          projectReferralsConnection,
        });
      },
      onError,
    },
    query: query ?? GET_REFERRAL_PROJECTS_QUERY,
    token,
  });

  const handleFetchMoreReferralProjects = ({
    after,
    first,
    partnerId,
  }: IHandleFetchMoreReferralProjectsParams): Promise<ApolloQueryResult<IGetReferralProjectsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          partners: { referralProjects: newlyFetchedReferralProjectsConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedReferralProjectsConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          partners: { referralProjects: previouslyFetchedReferralProjectsConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedReferralProjectsConnection;

        return {
          ...previousQueryResult,
          partners: {
            ...previouslyFetchedReferralProjectsConnection,
            referralProjects: {
              ...newlyFetchedReferralProjectsConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        partnerId,
      },
    });
  };

  const handleGetReferralProjects = ({ after, first, partnerId, product }: IHandleGetReferralProjectsParams) => {
    void executeGetReferralProjects({
      variables: {
        after,
        first,
        partnerId,
        product,
      },
    });
  };

  return {
    handleFetchMoreReferralProjects,
    handleGetReferralProjects,
    isLoading,
  };
};

export { useGetReferralProjects };
