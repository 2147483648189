import { useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBAsset } from '@netfront/common-library';
import { useCreateProjectAsset, useToast } from 'hooks';
import { pushImageToAws } from 'utils';


const useCreateProjectLogoWrapper = ({ token }: { token?: string }) => {
  const uploadedFileRef = useRef<{value: File | undefined}>({value: undefined });
  const dynamicCallBackRef = useRef<((asset?: IDBAsset) => void) | null>(null);

  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);

  const { handleToastError } = useToast();


  const { handleCreateProjectAsset, isLoading: isCreateProjectAssetLoading = false } = useCreateProjectAsset({
    onCompletedAsync: async (data) => {
      const {  signedUrl } = data;
      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, uploadedFileRef.current.value, () => {
        setIsAssetUploadToAwsLoading(false);
  
        if (dynamicCallBackRef.current) {
          dynamicCallBackRef.current(data);
        }
      
        uploadedFileRef.current.value = undefined;
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleCreateProjectLogo = ({
    callBack,
    projectId,
    uploadedFile,
  }: {
    callBack: (asset?: IDBAsset) => void;
    projectId?: string;
    uploadedFile?: File;
 
  }) => {

    dynamicCallBackRef.current = callBack;


    if (uploadedFile && projectId) {
      uploadedFileRef.current.value = uploadedFile;

      const { name, type, size } = uploadedFile;

      handleCreateProjectAsset({
        alt: name,
        fileName: name,
        fileSizeInBytes: size,
        contentType: type,
        projectId: String(projectId),
        type: 'IMAGE',
      });      
    } else {

      callBack();
    }
  };

  return {
    isLoading: isCreateProjectAssetLoading || isAssetUploadToAwsLoading,
    handleCreateProjectLogo,
  };
};

export { useCreateProjectLogoWrapper };