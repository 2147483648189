import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_ALL_ORGANISATIONS } from './useGetAllOrganisations.graphql';
import {
  IGetAllOrganisationsQueryGraphQLResponse,
  IGetAllOrganisationsQueryVariables,
  IHandleFetchMoreOrganisationsParams,
  IUseGetAllOrganisations,
  IUseGetAllOrganisationsOptions,
} from './useGetAllOrganisations.interfaces';

const useGetAllOrganisations = (options?: IUseGetAllOrganisationsOptions): IUseGetAllOrganisations => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetAllOrganisationsOptions);

  const [executeGetAllOrganisations, { loading: isLoading, fetchMore }] = useLoggedGeladaLazyQuery<
  IGetAllOrganisationsQueryGraphQLResponse,
  IGetAllOrganisationsQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          organisation: { getAllOrganisations: organisations },
        } = data;

        onCompleted({
          organisations,
        });
      },
      onError,
    },
    query: GET_ALL_ORGANISATIONS,
    token,
  });

  const handleFetchMoreOrganisations = ({
    after,
    first,
    filter,
  }: IHandleFetchMoreOrganisationsParams): Promise<ApolloQueryResult<IGetAllOrganisationsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          organisation: { getAllOrganisations: newlyFetchedProjectConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedProjectConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          organisation: { getAllOrganisations: previouslyFetchedProjectConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedProjectConnection;

        return {
          ...previousQueryResult,
          organisation: {
            ...previouslyFetchedProjectConnection,
            getAllOrganisations: {
              ...newlyFetchedProjectConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        filter,
      },
    });
  };

  const handleGetAllOrganisations = ({ after, filter, first }: IGetAllOrganisationsQueryVariables) => {
    void executeGetAllOrganisations({
      variables: {
        after,
        filter,
        first,
      },
    });
  };

  return {
    handleFetchMoreOrganisations,
    handleGetAllOrganisations,
    isLoading,
  };
};

export { useGetAllOrganisations };
