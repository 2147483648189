import { gql } from '@apollo/client';

const GET_REFERRAL_PROJECTS_QUERY = gql`
  query GetReferralProjects($after: String, $first: Int, $partnerId: Int!, $product: EProduct!) {
    partners {
      referralProjects(after: $after, first: $first, partnerId: $partnerId) {
        edges {
          node {
            partner {
              id
            }
            createdAtUtc
            customerDetail(product: $product) {
              subscriptions {
                plan {
                  name
                }
              }
            }

            id
            name
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_REFERRAL_PROJECTS_QUERY };
