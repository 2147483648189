import { useState } from 'react';

import { AvailableChart, AvailableChartProps, ChartPicker, LineChartCard, ILegend, ILineChartDataset } from '@netfront/ui-library';

import { DailyUsageProps } from './PdfDailyUsage.interfaces';

const PdfDailyUsage = ({ pdfs }: DailyUsageProps) => {
  const LABEL = 'Pdf daily usage';

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const PRIMARY_COLOR = '#972868';

  const lineChart: ILineChartDataset[] = [
    { backgroundColor: PRIMARY_COLOR, borderColor: PRIMARY_COLOR, data: pdfs.map((r) => r.count), label: 'Daily scans' },
  ];

  const legends: ILegend[] = [
    {
      color: PRIMARY_COLOR,
      label: 'Pdf generation',
    },
  ];

  const availableCharts: AvailableChartProps[] = [
    {
      value: 'Line',
      component: <LineChartCard datasets={lineChart} label={LABEL} labels={pdfs.map((r) => r.date.toString())} legends={legends} />,
    },
  ];

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_daily_pdf_usage_chart_picker"
      onChange={onActiveChartChange}
    />
  );
};

export { PdfDailyUsage };
