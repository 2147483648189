import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { LINK_ORGANISATION_MUTATION } from './useLinkOrganisation.graphql';
import {
  ILinkOrganisationMutationGraphQLResponse,
  ILinkOrganisationMutationVariables,
  IHandleLinkOrganisationParams,
  IUseLinkOrganisationOptions,
  IUseLinkOrganisation,
} from './useLinkOrganisation.interfaces';

const useLinkOrganisation = (options?: IUseLinkOrganisationOptions): IUseLinkOrganisation => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseLinkOrganisationOptions);

  const [executeLinkOrganisation, { loading: isLoading }] = useLoggedGeladaMutation<
    ILinkOrganisationMutationGraphQLResponse,
    ILinkOrganisationMutationVariables
  >({
    mutation: mutation ?? LINK_ORGANISATION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partner: { linkOrganisation: isCompleted },
        } = data;

        onCompleted({
          result: isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleLinkOrganisation = ({ input }: IHandleLinkOrganisationParams) => {
    void executeLinkOrganisation({
      variables: {
        input,
      },
    });
  };

  return {
    handleLinkOrganisation,
    isLoading,
  };
};

export { useLinkOrganisation };
