import React, { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useToast, useProtectedRoute, useGetReferralProjects, useGetProduct } from 'hooks';
import { IDBReferralProject } from 'interfaces';
import last from 'lodash.last';
import Link from 'next/link';

import { REFERRALS_TABLE_COLUMNS } from './PartnerManagementReferralsPage.constants';
import { getReferralsTableData } from './PartnerManagementReferralsPage.helpers';
import { IReferralsTableData } from './PartnerManagementReferralsPage.interfaces';

const PartnerManagementReferralsPage = () => {
  const { handleToastError } = useToast();

  const { partner } = useContext(CachingEntitiesContext);

  const { getAccessTokenCookie } = useCookie();
  const { getProduct } = useGetProduct();
  const { isAuthenticated } = useProtectedRoute();

  const [allProjectReferrals, setAllProjectReferrals] = useState<IDBReferralProject[]>([]);
  const [filteredReferrals, setFilteredReferrals] = useState<IDBReferralProject[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [referralsTableData, setReferralsTableData] = useState<IReferralsTableData[]>([]);
  const [totalProjectReferrals, setTotalProjectReferrals] = useState<number>(0);

  const token = getAccessTokenCookie();

  const {
    handleGetReferralProjects,
    handleFetchMoreReferralProjects,
    isLoading: isGetReferralProjectsLoading = false,
  } = useGetReferralProjects({
    fetchPolicy: 'no-cache',
    onCompleted: ({ projectReferralsConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const projectReferrals = edges.map(({ node }) => node);

      setFilteredReferrals(projectReferrals);
      setAllProjectReferrals(projectReferrals);
      setIsLoadMoreDisabled(projectReferrals.length >= totalCount || totalCount <= pageSize);
      setTotalProjectReferrals(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    if (!partner) return;
    const { id } = partner;

    void handleFetchMoreReferralProjects({
      after: lastCursor,
      first: pageSize,
      partnerId: id,
      product: getProduct(),
    });
  };

  useEffect(() => {
    setReferralsTableData(
      getReferralsTableData({
        referrals: filteredReferrals,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredReferrals]);

  useEffect(() => {
    if (!isAuthenticated || !partner) {
      return;
    }

    handleGetReferralProjects({
      partnerId: partner.id,
      product: getProduct(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pageSize, partner]);

  useEffect(() => {
    setFilteredReferrals(allProjectReferrals.filter(({ name }) => name?.toLowerCase().includes(String(filter ?? '').toLowerCase())));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);


  return (
    <TablePageTemplate<IReferralsTableData>
      activePage="referrals"
      breadcrumbItems={[{
        content: <Link href={`/partner/dashboard`}><span>Partners</span></Link>,
        key: 'partners',
        
      },
      {
        content: 'Referrals',
        key: 'referrals',
      }]}
      columns={REFERRALS_TABLE_COLUMNS}
      data={referralsTableData}
      description="Partner referrals"
      handleOnPageSizeChange={handlePageSizeChange}
      handleOnPaginate={async () => {
        if (!partner) return;
        const { id } = partner;
        await handleFetchMoreReferralProjects({
          after: lastCursor,
          first: pageSize,
          partnerId: id,
          product: getProduct(),
        });
      }}
      handleSearch={handleFilterSearch}
      informationBoxMessage="As a partner, you have access to manage all partner-managed projects. You will receive a trailing commission for both partner and user managed projects however you will not have access to user managed dashboards."
      initialSearchValue={filter}
      isLoading={isGetReferralProjectsLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      pageSize={pageSize}
      pageTitle="Partner management referrals"
      searchPlaceholder="Search"
      size="small"
      tableType="referral projects"
      title="Referrals"
      totalItems={totalProjectReferrals}
    />
  );
};

export { PartnerManagementReferralsPage };
