import { gql } from '@apollo/client';

const GET_TEAM_QUERY = gql`
  query GetTeam($after: String, $first: Int, $partnerId: Int!) {
    partnerUser {
      team(after: $after, first: $first, partnerId: $partnerId) {
        edges {
          cursor
          node {
            user {
              id
              credential {
                email
              }
              firstname
              lastname
            }
            permission
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_TEAM_QUERY };
