import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ImageUpload, Input, Spacing, Textarea } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { SidebarContainer } from 'components/SidebarContainer';

import { upsertProjectGeneralTabConstants } from './UpsertProjectGeneralTab.constants';
import { UpsertProjectGeneralTabProps } from './UpsertProjectGeneralTab.interfaces';

const UpsertProjectGeneralTab = ({ control, onDrop, isUpdate = false }: UpsertProjectGeneralTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const { descriptionCharacterMaxLength, titleCharacterMaxLength } = upsertProjectGeneralTabConstants;

  return (
    <SidebarContainer>
      <Spacing size="large">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_project_name"
              labelText="Title"
              maxLength={titleCharacterMaxLength}
              tooltipText="Workspace title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      
      {isUpdate ? (
        <>
          <Spacing size="large">
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <Textarea
                  id="id_project_description"
                  labelText="Description"
                  maxLength={descriptionCharacterMaxLength}
                  tooltipText="Workspace description"
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>

          <Spacing size="large">
            <Controller
              control={control}
              name="backOfficeUrl"
              render={({ field }) => (
                <Input
                  id="id_project_url"
                  labelText="Url"
                  tooltipText="Workspace url"
                  type="text"
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>
          <Spacing>
            <Controller
              control={control}
              name="presignedUrl"
              render={({ field }) => (
                <ImageUpload
                  id="id_project_logo"
                  initialUrl={field.value}
                  labelText="Avatar (optional)"
                  name="logoUrl"
                  tooltipText="Upload your logo"
                  isLabelSideBySide
                  onDrop={onDrop}
                />
              )}
            />
          </Spacing>
        </>
      ) : <></>}
    </SidebarContainer>
  );
};

export { UpsertProjectGeneralTab };
