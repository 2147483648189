import { useState } from 'react';

import { AvailableChartProps, ChartPicker, AvailableChart, IBarChartDataset, ILegend, BarChartCard, LineChartCard, ILineChartDataset} from '@netfront/ui-library';
import { getSum, mergeDailyCounts } from 'utils';

import { TotalProjectsProps } from './TotalProjects.interfaces';

const TotalProjects = ({ comparisonPeriodDailyCount, selectedPeriodDailyCount }: TotalProjectsProps) => {
  const LABEL = 'Total projects created';

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const THIS_PERIOD_COLOR = '#972868';
  const COMPARISON_COLOR = '#4BC2CA';
  const merged = mergeDailyCounts(selectedPeriodDailyCount, comparisonPeriodDailyCount);
  const barData: IBarChartDataset[] = [
    {
      backgroundColor: [THIS_PERIOD_COLOR],
      label: 'Values',
      id: 'id_time_period',
      data: merged.map((r) => r.current),
    },
    {
      backgroundColor: [COMPARISON_COLOR],
      label: 'Values',
      id: 'id_comparison',
      data: merged.map((r) => r.previous),
    },
  ];
  const labels = merged.map((r) => r.date.toString());

  const lineChart: ILineChartDataset[] = [
    { backgroundColor: THIS_PERIOD_COLOR, borderColor: THIS_PERIOD_COLOR, data: merged.map((r) => r.current), label: 'This period' },
    { backgroundColor: COMPARISON_COLOR, borderColor: COMPARISON_COLOR, data: merged.map((r) => r.previous), label: 'Comparison period' },
  ];

  const sum = getSum(selectedPeriodDailyCount, comparisonPeriodDailyCount);

  const legends: ILegend[] = [
    {
      color: THIS_PERIOD_COLOR,
      label: 'Current period',
    },
    {
      color: COMPARISON_COLOR,
      label: 'Comparison period',
    },
  ];

  const availableCharts: AvailableChartProps[] = [
    { value: 'Line', component: <LineChartCard datasets={lineChart} displayValue={String(sum)} label={LABEL} labels={labels} legends={legends} /> },
    {
      value: 'Bar',
      component: <BarChartCard datasets={barData} displayedValue={String(sum)} label={LABEL} labels={labels} legends={legends} />,
    },
  ];

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_total_projects_chart_picker"
      onChange={onActiveChartChange}
    />
  );
};
export { TotalProjects };
