import { useCookie } from '@netfront/common-library';

import { PROJECT_PRODUCT_MAP } from './useGetProduct.constants';
import { IUseGetProduct } from './useGetProduct.interfaces';

const useGetProduct = (): IUseGetProduct => {
  const { getProjectNameCookie } = useCookie();

  const getProduct = () => {
    const projectName = getProjectNameCookie();

    if (!projectName) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return PROJECT_PRODUCT_MAP[projectName.toUpperCase()] ?? 'EKARDO'; //If the name is not link to any product, we assume it's an Ekardo project
  };

  return {
    getProduct,
  };
};

export { useGetProduct };
