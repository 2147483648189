import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { INVITE_USER_IN_PARTNER } from './useInvitePartnerUser.graphql';
import {
  IInvitePartnerUserMutationGraphQLResponse,
  IInvitePartnerUserMutationVariables,
  IHandleInvitePartnerUserParams,
  IUseInvitePartnerUserOptions,
  IUseInvitePartnerUser,
} from './useInvitePartnerUser.interfaces';

const useInvitePartnerUser = (options?: IUseInvitePartnerUserOptions): IUseInvitePartnerUser => {
  const { onCompleted, onError, token, product } = options ?? ({} as IUseInvitePartnerUserOptions);

  const [executeInvitePartnerUser, { loading: isLoading }] = useLoggedGeladaMutation<
    IInvitePartnerUserMutationGraphQLResponse,
    IInvitePartnerUserMutationVariables
  >({
    mutation: INVITE_USER_IN_PARTNER,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          invitation: { partnerInvitation },
        } = data;

        onCompleted({
          result: partnerInvitation,
        });
      },
      onError,
    },
    token,
    product,
  });

  const handleInvitePartnerUser = ({ input }: IHandleInvitePartnerUserParams) => {
    void executeInvitePartnerUser({
      variables: {
        input,
      },
    });
  };

  return {
    handleInvitePartnerUser,
    isLoading,
  };
};

export { useInvitePartnerUser };
