import { useEffect, useState, useContext } from 'react';

import { Dropdown, OrganisationIcon, OrganisationNavigation, OrganisationNavigationItemProps, ProjectsIcon, SwitchIcon, TeamIcon } from '@netfront/ui-library';

import { CachingEntitiesContext } from '../../context';

const OrganisationMenu = () => {
  const { clearCurrentPartner, dashboardUrl, partner } = useContext(CachingEntitiesContext);

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<OrganisationNavigationItemProps[]>([]);

  useEffect(() => {
    if (!(dashboardUrl && partner)) {
      return;
    }

    const dropdownNavigationItems: OrganisationNavigationItemProps[] = [
      {
        header: {
          title: `Current account`,
          name: partner.name,
          icon: SwitchIcon,
          href: '',
          onClick: clearCurrentPartner,
        },
        options: [
          {
            icon: TeamIcon,
            label: 'Team',
            href: '/partner/team',
          },
          {
            icon: ProjectsIcon,
            label: 'Projects',
            href: '/partners',
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardUrl, partner]);

  return (
    <div className="c-organisation-menu">
      {partner && (
        <Dropdown
          dropdownId="organisation-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<OrganisationIcon />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <div className="c-organisation-menu">
            <OrganisationNavigation
              dropdownNavigationItems={navigationList}
            />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export { OrganisationMenu };
