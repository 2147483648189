import { useMuriquiLazyQuery } from '../useMuriquiLazyQuery';

import { GET_INVOICE_TRANSACTIONS_QUERY } from './useGetTransactions.graphql';
import {
  IGetTransactionsQueryGraphQLResponse,
  IGetTransactionsQueryVariables,
  IUseGetTransactions,
  IUseGetTransactionsOptions,
} from './useGetTransactions.interfaces';

const useGetTransactions = (options?: IUseGetTransactionsOptions): IUseGetTransactions => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetTransactionsOptions);

  const [executeGetTransactions, { loading: isLoading }] = useMuriquiLazyQuery<
    IGetTransactionsQueryGraphQLResponse,
    IGetTransactionsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          transactions: { getInvoiceTransactions: transactionsConnection },
        } = data;

        onCompleted({
          transactionsConnection,
        });
      },
      onError,
    },
    query: query ?? GET_INVOICE_TRANSACTIONS_QUERY,
    token,
  });

  const handleGetTransactions = () => {
    void executeGetTransactions();
  };

  return {
    handleGetTransactions,
    isLoading,
  };
};

export { useGetTransactions };
