import React, { useEffect, useState } from 'react';

import { CaretIcon, NavigationButton } from '@netfront/ui-library';
import { TablePageTemplate, TransactionSidebarView } from 'components';
import format from 'date-fns/format';
import { IDBInvoiceTransaction } from 'interfaces';
import Link from 'next/link';

import { TRANSACTIONS_TABLE_COLUMNS } from './TransactionsPage.constants';
import { getTransactionsTableData } from './TransactionsPage.helpers';
import { ITransactionsTableData, TransactionsPageProps } from './TransactionsPage.interfaces';

const TransactionsPage = ({ selectedPayoutTransactions, selectedPayout, onDisplayTransactionsTable }: TransactionsPageProps) => {
  const [filteredTransactions, setFilteredTransactions] = useState<IDBInvoiceTransaction[]>(selectedPayoutTransactions);
  const [filter, setFilter] = useState<string>();
  const [transactionsTableData, setTransactionsTableData] = useState<ITransactionsTableData[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<IDBInvoiceTransaction>();

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };

  const handleSideBarClose = () => {
    setSelectedTransaction(undefined);
    setIsSideBarOpen(false);
  };

  useEffect(() => {
    setTransactionsTableData(
      getTransactionsTableData({
        transactions: filteredTransactions,
        onSettingsButtonClick: (transaction: IDBInvoiceTransaction) => {
          setSelectedTransaction(transaction);
          setIsSideBarOpen(true);
        }
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTransactions]);

  useEffect(() => {
    if (!filter) {
      setFilteredTransactions(selectedPayoutTransactions);
    } else {
      setFilteredTransactions(
        selectedPayoutTransactions.filter(({ description }) => description?.toLowerCase().includes(filter.toLowerCase())),
      );
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <TablePageTemplate<ITransactionsTableData>
        activePage="payouts"
        breadcrumbItems={[{
          content: <Link href={`/partner/dashboard`}><span>Partners</span></Link>,
          key: 'partners',
            
        },
        {
          content: 'Payouts',
          key: 'payouts',
          onClick: () => onDisplayTransactionsTable(undefined),
        },
        {
          content: 'Monthly breakdown',
          key: '1',
        },]}
          
        childrenEnd={
          <NavigationButton
            additionalClassNames="c-action__back-to-action"
            direction="previous"
            icon={CaretIcon}
            rotationCssSuffix="90"
            text="Return to payouts"
            onClick={() => onDisplayTransactionsTable(undefined)}
          />
        }
        columns={TRANSACTIONS_TABLE_COLUMNS}
        data={transactionsTableData}
        description="Manage partner"
        handleSearch={handleFilterSearch}
        informationBoxMessage={`Total amount paid ${selectedPayout.amount || 0} AUD`}
        initialSearchValue={filter}
        pageTitle="Partner management payouts"
        searchPlaceholder="Search"
        size="small"
        tableType="Payouts"
        title={`${format(Date.parse(String(selectedPayout.start)), 'do MMMM')} - ${format(
          Date.parse(String(selectedPayout.end)),
          'do MMMM',
        )}`}
      />
      <TransactionSidebarView
        handleSideBarClose={handleSideBarClose}
        isSideBarOpen={isSideBarOpen}
        selectedTransaction={selectedTransaction}
      />
    </>
  );
};

export { TransactionsPage };
