import { gql } from '@apollo/client';

const GET_TREND = gql`
  query getTrend {
    trend {
      get {
        organisationCount
        organisationTrend
        partnerCount
        partnerTrend
        projectCount
        projectTrend
      }
    }
  }
`;

export { GET_TREND };
