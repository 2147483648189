import { getBEMClassName, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, Textarea } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';

import { CONTACT_FORM_BLOCK_CSS_IDENTIFIERS, CONTACT_FORM_INITIAL_VALUES } from './ContactForm.constants';
import { IContactForm, IContactFormFields } from './ContactForm.interfaces';

import 'yup-phone';

const ContactForm = ({ buttonText = 'Send', isSubmitting, onSend }: IContactForm) => {
  const { form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    message: { id: messageId, label: messageLabel },
  } = FORM_FIELDS;

  const { form: formBlockCssId } = CONTACT_FORM_BLOCK_CSS_IDENTIFIERS;

  const { control, handleSubmit } = useForm<IContactFormFields>({
    defaultValues: CONTACT_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <form
      className={getBEMClassName(formBlockCssId, formElementCssId)}
      onSubmit={(event) => {
        event.preventDefault();
        void handleSubmit(onSend)();
      }}
    >
      <div className="c-form__body">
        <div className="c-form__field">
          <Controller
            control={control}
            name="message"
            render={({ field, fieldState }) => (
              <Textarea
                additionalClassNames={getBEMClassName(formBlockCssId, messageId)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={`${messageId}-contact`}
                isDisabled={isSubmitting}
                labelText={messageLabel}
                isRequired
                {...field}
              />
            )}
          />
        </div>
      </div>

      <div className="c-form__footer">
        <Button isDisabled={isSubmitting} size="xs" text={buttonText} type="submit" />
      </div>
    </form>
  );
};

export { ContactForm };
