import { useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IUpdatePartnerInput, useToast, useUpdatePartner, useUpdateTheme } from 'hooks';
import { IDBPartnerLogoThemeInput } from 'interfaces';



const useUpdatePartnerWrapper = ({ token }: { token?: string}) => {
  const dynamicCallBackRef = useRef<(() => void) | null>(null);

  const [hasUpdatedPartner, setHasUpdatedPartner] = useState<boolean>(false);
  const [hasUpdatedTheme, setHasUpdatedTheme] = useState<boolean>(false);

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };


  const cleanUpAndReturn = () => {
    handleToastSuccess({
      message: 'Partner updated successfully',
    });
    if (dynamicCallBackRef.current) {
      dynamicCallBackRef.current();
    }

    setHasUpdatedPartner(false);
    setHasUpdatedTheme(false);
  };

  

  const { handleUpdateTheme: executeUpdateTheme, isLoading: isUpdateThemeLoading = false } = useUpdateTheme({
    onCompleted: () => {
      setHasUpdatedTheme(true);

      if (hasUpdatedPartner) cleanUpAndReturn();
      
    },
    onError: handleGetError,
    token,
  });

  const { handleUpdatePartner: executeUpdatePartner, isLoading: isUpdatePartnerLoading = false } = useUpdatePartner({
    onCompleted: () => {

      setHasUpdatedPartner(true);

      if (hasUpdatedTheme) cleanUpAndReturn();
      
     
    },
    onError: handleGetError,
    token,
  });


  const handleUpdatePartner = ({
    partnerInput,
    themeInput,
    callBack,
  }: {
    callBack: () => void;
    partnerInput: IUpdatePartnerInput;
    themeInput: IDBPartnerLogoThemeInput;
  }) => {
    dynamicCallBackRef.current = callBack;

    executeUpdatePartner({
      input: partnerInput
    });

    executeUpdateTheme({
      input: themeInput
    })
  };

  return {
    isLoading: isUpdatePartnerLoading || isUpdateThemeLoading,
    handleUpdatePartner,
  };
};
export { useUpdatePartnerWrapper };
