import { gql } from '@apollo/client';

const GET_PAYOUTS = gql`
  query GetPayouts($after: String, $first: Int, $partnerId: Int!, $product: EProduct!, $start: DateTime!, $end: DateTime!) {
    partners {
      payouts(after: $after, first: $first, partnerId: $partnerId, product: $product, start: $start, end: $end) {
        edges {
          node {
            amount
            end
            start
            transactions {
              amount
              email
              status
              name
              description
              createdAtUtc
              customer {
                name
                description
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_PAYOUTS };
