import { gql } from '@apollo/client';

const CREATE_PARTNER_LOGO_ASSET_MUTATION = gql`
  mutation CreateAsset($input: CreatePartnerAssetInput!) {
    asset {
      createPartnerAsset(asset: $input) {
        asset {
          contentType
          fileName
          assetId
          presignedUrl
          s3Key
        }
        signedUrl
      }
    }
  }
`;

export { CREATE_PARTNER_LOGO_ASSET_MUTATION };
