import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_GELADA_ORGANISATION_PROJECTS_QUERY } from './useGetGeladaOrganisationProjects.graphql';
import {
  IGetGeladaOrganisationProjectsQueryGraphQLResponse,
  IGetGeladaOrganisationProjectsQueryVariables,
  IHandleFetchMoreGetGeladaOrganisationProjectsParams,
  IHandleGetGeladaOrganisationProjectsParams,
  IUseGetGeladaOrganisationProjects,
  IUseGetGeladaOrganisationProjectsOptions,
} from './useGetGeladaOrganisationProjects.interfaces';

const useGetGeladaOrganisationProjects = (options?: IUseGetGeladaOrganisationProjectsOptions): IUseGetGeladaOrganisationProjects => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetGeladaOrganisationProjectsOptions);

  const [executeGetGeladaOrganisationProjects, { fetchMore, loading: isLoading }] = useLoggedGeladaLazyQuery<
  IGetGeladaOrganisationProjectsQueryGraphQLResponse,
  IGetGeladaOrganisationProjectsQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { organisationProjects: projectsConnection },
        } = data;

        onCompleted({
          projectsConnection,
        });
      },
      onError,
    },
    query: query ?? GET_GELADA_ORGANISATION_PROJECTS_QUERY,
    token,
  });

  const handleFetchMoreGetGeladaOrganisationProjects = ({
    after,
    first,
    organisationKey,
    filter,
  }: IHandleFetchMoreGetGeladaOrganisationProjectsParams): Promise<
  ApolloQueryResult<IGetGeladaOrganisationProjectsQueryGraphQLResponse>
  > => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          project: { organisationProjects: newlyFetchedGeladaOrganisationProjectsConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedGeladaOrganisationProjectsConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          project: { organisationProjects: previouslyFetchedGeladaOrganisationProjectsConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedGeladaOrganisationProjectsConnection;

        return {
          ...previousQueryResult,
          projects: {
            ...previouslyFetchedGeladaOrganisationProjectsConnection,
            organisationProjects: {
              ...newlyFetchedGeladaOrganisationProjectsConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        organisationKey,
        filter,
      },
    });
  };

  const handleGetGeladaOrganisationProjects = ({ after, first, organisationKey, filter }: IHandleGetGeladaOrganisationProjectsParams) => {
    void executeGetGeladaOrganisationProjects({
      variables: {
        after,
        first,
        organisationKey,
        filter,
      },
    });
  };

  return {
    handleFetchMoreGetGeladaOrganisationProjects,
    handleGetGeladaOrganisationProjects,
    isLoading,
  };
};

export { useGetGeladaOrganisationProjects };
