import { useState } from 'react';

import { AvailableChart, ChartPicker, LineChartCard, ILegend, ILineChartDataset, AvailableChartProps } from '@netfront/ui-library';

import { SpeechDailyUsageProps } from './SpeechDailyUsage.interfaces';

const SpeechDailyUsage = ({ speeches }: SpeechDailyUsageProps) => {
  const LABEL = 'Speech use';

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const PRIMARY_COLOR = '#972868';

  const lineChart: ILineChartDataset[] = [
    { backgroundColor: PRIMARY_COLOR, borderColor: PRIMARY_COLOR, data: speeches.map((r) => r.count), label: 'Daily scans' },
  ];

  const legends: ILegend[] = [
    {
      color: PRIMARY_COLOR,
      label: 'Speech action',
    },
  ];

  const availableCharts: AvailableChartProps[] = [
    {
      value: 'Line',
      component: <LineChartCard datasets={lineChart} label={LABEL} labels={speeches.map((r) => r.date.toString())} legends={legends} />,
    },
  ];

  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_speech_daily_chart_picker"
      onChange={onActiveChartChange}
    />
  );
};

export { SpeechDailyUsage };
