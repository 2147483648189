import { Avatar, Button, NewWindowIcon, PayoutsIcon } from "@netfront/ui-library";
import { Column } from "react-table";

import { IMuriquiProjectsTableData } from "./MuriquiProjectsPage.interfaces";


export const QR_CODE_PRODUCT_ID = 1;
export const PDF_PRODUCT_ID = 4;
export const SPEECH_PRODUCT_ID = 3;
export const TRANSLATE_PRODUCT_ID = 2;

const TOOLTIP_CONSTANTS = Object.freeze({
  PAID: 'The project has at least one paid subscription on the product',
  UNPAID: 'The project has not any paid subscription on the product',
});

const PRODUCT_PREFIXES: {[key: number]: string} = {
  [QR_CODE_PRODUCT_ID]: '',
  [PDF_PRODUCT_ID]: 'PDF: ',
  [SPEECH_PRODUCT_ID]: 'Speech: ',
  [TRANSLATE_PRODUCT_ID]: 'Translate: '
};

const MURIQUI_PROJECTS_TABLE_COLUMNS: ReadonlyArray<Column<IMuriquiProjectsTableData>> = [
  {
    accessor: 'name',
    Cell: ({ value }) => (
      <div className="c-projects-table__projects-cell">
        <Avatar size="small" title={value} />
        {value}
      </div>
    ),
    Header: () => <div>Project</div>,
    width: '45%',
  },
  {
    accessor: 'plans',
    Cell: ({ value }) => <div>{value.map((plan, index) => <p key={index}>{plan}</p>)}</div>,
    Header: () => <div>Plan(s)</div>,
    width: '20%',
  },
  {
    accessor: 'subscriptionRenewal',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Subscription renewal</div>,
    width: '20%',
  },
  {
    Header: () => <div className="h-hide-visually">Settings</div>,  
    accessor: 'settingsButtonData',
    Cell: ({ value: { id: projectId, isPaid, onClick } }) => (
      <div className="c-projects-table__projects-cell">
        <Button
          icon={NewWindowIcon}
          size="xs"
          text="Dashboard"
          variant="secondary"
          onClick={() => onClick(projectId)}
        ></Button>
        <div title={isPaid ? TOOLTIP_CONSTANTS.PAID : TOOLTIP_CONSTANTS.UNPAID}>
          <PayoutsIcon className={`icon_project_paid ${isPaid ? ' paid' : 'unpaid'}`} />
        </div>
      </div>
    ),
    width: '15%',
  },
];


export { PRODUCT_PREFIXES, TOOLTIP_CONSTANTS, MURIQUI_PROJECTS_TABLE_COLUMNS };
