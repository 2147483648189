import { gql } from '@apollo/client';

const CREATE_PROJECT_ASSET_MUTATION = gql`
  mutation CreateProjectAsset(
    $projectId: String!
    $type: EAssetType!
    $fileName: String!
    $contentType: String!
    $alt: String!
    $fileSizeInBytes: Int!
  ) {
    asset {
      createAsset(
        asset: {
          projectId: $projectId
          type: $type
          fileName: $fileName
          contentType: $contentType
          alt: $alt
          fileSizeInBytes: $fileSizeInBytes
        }
      ) {
        asset {
          assetId
          presignedUrl
        }
        signedUrl
      }
    }
  }
`;

export { CREATE_PROJECT_ASSET_MUTATION };
