import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, Spacing } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { createOrganisationGeneralTabConstants } from './CreateOrganisationGeneralTab.constants';
import { CreateOrganisationGeneralTabProps } from './CreateOrganisationGeneralTab.interfaces';

const CreateOrganisationGeneralTab = ({ control }: CreateOrganisationGeneralTabProps) => {
  const { titleCharacterMaxLength } = createOrganisationGeneralTabConstants;

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>
      <Spacing size="2x-large">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_account_name"
              labelText="Create your account"
              maxLength={titleCharacterMaxLength}
              tooltipText="Workspace name"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
    </SidebarContainer>

  );
};

export { CreateOrganisationGeneralTab };
