import { gql } from '@apollo/client';

const UPDATE_PROJECT_LOGO_MUTATION = gql`
  mutation UpdateLogo($projectId: String!, $id: String!) {
    project {
      updateLogo(logo: { projectId: $projectId, id: $id }) {
        logo {
          assetId
          presignedUrl
        }
      }
    }
  }
`;

export { UPDATE_PROJECT_LOGO_MUTATION };
