import { gql } from '@apollo/client';

const UPDATE_THEME_MUTATION = gql`
  mutation UpdateTheme($input: UpdateThemeInputGraphType!) {
    partner {
      updateTheme(input: $input) {
        result
      }
    }
  }
`;

export { UPDATE_THEME_MUTATION };
