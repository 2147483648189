import { DailyCount } from 'interfaces';

export interface IMergedDailyCount {
  current: number;
  date: Date;
  previous: number;
}
const getSum = (current: DailyCount[], previous: DailyCount[]): number => {
  let sum = 0;
  current.map((r) => (sum += r.count));
  previous.map((r) => (sum += r.count));
  return sum;
};

const mergeDailyCounts = (current: DailyCount[], previous: DailyCount[]): IMergedDailyCount[] => {
  const updated = current.map((currentObj) => {
    const previousObj = previous.find((prevObj) => prevObj.date === currentObj.date);
    const merged: IMergedDailyCount = {
      date: currentObj.date,
      current: currentObj.count,
      previous: previousObj ? previousObj.count : 0,
    };
    return merged;
  });

  previous.forEach((prevObj) => {
    const doesExists = updated.some((updatedObj) => updatedObj.date === prevObj.date);
    if (!doesExists) {
      const merged: IMergedDailyCount = {
        date: prevObj.date,
        current: 0,
        previous: prevObj.count,
      };
      updated.push(merged);
    }
  });

  updated.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    } else if (a.date > b.date) {
      return 1;
    } else {
      return 0;
    }
  });

  return updated;
};
export { mergeDailyCounts, getSum };
