import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_GELADA_INSIGHTS } from './useGetGeladaInsights.graphql';
import {
  IGetGeladaInsightsQueryGraphQLResponse,
  IGetGeladaInsightsQueryVariables,
  IHandleGetGeladaInsightsParams,
  IUseGetGeladaInsights,
  IUseGetGeladaInsightsOptions,
} from './useGetGeladaInsights.interfaces';

const useGetGeladaInsights = (options?: IUseGetGeladaInsightsOptions): IUseGetGeladaInsights => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetGeladaInsightsOptions);

  const [executeGetGeladaInsights, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGeladaInsightsQueryGraphQLResponse,
    IGetGeladaInsightsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          trend: {
            insights: { activePartners, projectsCreationDuringComparisonPeriod, projectsCreationDuringSelectedPeriod },
          },
        } = data;

        onCompleted({
          activePartners,
          projectsCreationDuringComparisonPeriod,
          projectsCreationDuringSelectedPeriod,
        });
      },
      onError,
    },
    query: GET_GELADA_INSIGHTS,
    token,
  });

  const handleGetGeladaInsights = ({ end, product, start }: IHandleGetGeladaInsightsParams) => {
    void executeGetGeladaInsights({
      variables: {
        end,
        product,
        start,
      },
    });
  };

  return {
    handleGetGeladaInsights,
    isLoading,
  };
};

export { useGetGeladaInsights };
