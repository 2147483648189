import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PARTNERS_FOR_CONNECTED_USER_QUERY } from './useGetPartnersForConnectedUser.graphql';
import {
  IGetPartnersForConnectedUserQueryGraphQLResponse,
  IGetPartnersForConnectedUserQueryVariables,
  IHandleGetPartnersForConnectedUserParams,
  IUseGetPartnersForConnectedUser,
  IUseGetPartnersForConnectedUserOptions,
} from './useGetPartnersForConnectedUser.interfaces';

const useGetPartnersForConnectedUser = (options?: IUseGetPartnersForConnectedUserOptions): IUseGetPartnersForConnectedUser => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPartnersForConnectedUserOptions);

  const [executeGetPartnersForConnectedUser, { loading: isLoading }] = useLoggedGeladaLazyQuery<
  IGetPartnersForConnectedUserQueryGraphQLResponse,
  IGetPartnersForConnectedUserQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partner: { forConnectedUser: partnersConnection },
        } = data;

        onCompleted({
          partnersConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PARTNERS_FOR_CONNECTED_USER_QUERY,
    token,
  });

  const handleGetPartnersForConnectedUser = ({ after, first }: IHandleGetPartnersForConnectedUserParams) => {
    void executeGetPartnersForConnectedUser({
      variables: {
        after,
        first,
      },
    });
  };

  return {
    handleGetPartnersForConnectedUser,
    isLoading,
  };
};

export { useGetPartnersForConnectedUser };
