import { gql } from '@apollo/client';

const UPDATE_PARTNER_MUTATION = gql`
  mutation UseUpdatePartner($input: UpdatePartnerInputGraphType!) {
    partner {
      updatePartner(input: $input) {
        result
      }
    }
  }
`;

export { UPDATE_PARTNER_MUTATION };
