import { ApolloError } from '@apollo/client';
import { IDBGeladaProject, useCreateGeladaProject, useUpdateGeladaProject } from '@netfront/gelada-identity-library';
import { useToast } from 'hooks';
import kebabCase from 'lodash.kebabcase';



const useUpsertProject = ({ onUpsert, token  }: { onUpsert: (returnedProject: IDBGeladaProject) => void; token?: string }) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleCreateGeladaProject, isLoading: isCreateGeladaProjectLoading = false } = useCreateGeladaProject({
    onCompleted: ({ project: returnedProject }) => {
      handleToastSuccess({
        message: 'Project successfully created',
      });
      onUpsert(returnedProject);
    },
    onError: handleGetError,
    token,
  });

  const { handleUpdateGeladaProject, isLoading: isUpdateGeladaProjectLoading = false } = useUpdateGeladaProject({
    onCompleted: ({ project: returnedProject }) => {
      handleToastSuccess({
        message: 'Project successfully updated',
      });
      onUpsert(returnedProject);
    },
    onError: handleGetError,
    token,
  });


  const handleUpsertProject = ({
    backOfficeUrl = '',
    description = '',
    name,
    organisationId,
    projectId,
  }: {
    backOfficeUrl?: string;
    description?: string;
    name: string;
    organisationId: number;
    projectId?: string;
  }) => {

    if (!projectId) {
      void handleCreateGeladaProject({
        isCustomBuild: false,
        name,
        organisationId: organisationId,
        key: kebabCase(name)
      });
    } else {
      void handleUpdateGeladaProject({
        name,
        projectId,
        description,
        url: backOfficeUrl,
      });
    }
  };

  return {
    isLoading: isCreateGeladaProjectLoading || isUpdateGeladaProjectLoading,
    handleUpsertProject,
  };
};
export { useUpsertProject };
