import { useContext, useEffect, useState } from 'react';

import { AppHeader, Footer, PageLayoutTemplate, Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import { UserAccountMenu, OrganisationMenu } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetProduct } from 'hooks';
import Head from 'next/head';

import { IMeta, PageLayoutProps } from './PageLayout.types';

const PageLayout: React.FC<PageLayoutProps> = ({
  additionalClassNames,
  children,
  hasPrivateLayout,
  isPreloaderVisible = false,
  meta,
  title,
  isDisplayLayoutTemplate = true,
  isManagementLevel = false,
}) => {
  const { getProduct } = useGetProduct();
  const { description = 'Description' } = meta ?? ({} as IMeta);
  const [productImageUrl, setProductImageUrl] = useState<string>('');

  const { partner } = useContext(CachingEntitiesContext);

  useEffect(() => {
    if (!isManagementLevel) return;

    const product = getProduct();

    if (!product) return;

    setProductImageUrl(`/images/${product.toLowerCase()}-logo-full.svg`);

  }, [getProduct, isManagementLevel]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />
      </Head>

      {isDisplayLayoutTemplate ? (
        <PageLayoutTemplate
          additionalClassNames={cx('c-page-layout-template', additionalClassNames)}
          footer={<Footer projectName="Netfront" />}
          header={
            <AppHeader
              logo={{
                href: !isManagementLevel ? '/partner/dashboard': undefined ,
                imageSrc: !isManagementLevel ? partner?.logo?.presignedUrl: productImageUrl,
              }}
            >
              {hasPrivateLayout && (
                <>
                  {!isManagementLevel && (<OrganisationMenu />)}
                  <UserAccountMenu />
                </>
              )}
            </AppHeader>
          }
        >
          <Spinner isLoading={isPreloaderVisible} />

          <main className="c-page-layout-template__main">
            {children}
          </main>
        </PageLayoutTemplate>
      ) : (
        children
      )}
    </>
  );
};

export { PageLayout };
