/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { IDBPartnerTeam } from "interfaces";

const getTeamDefaultValues = ({ team }: { team?: IDBPartnerTeam}): FormFieldProps =>  {

  const {
    user,
    permission,
  } = team ?? {};

  const { 
    credential,
    firstname: firstName = '',
    lastname: lastName = '',
    id,
  } = user ?? {};

  const { email } = credential ?? {};


  return {
    permission: permission || '',
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    id: id ? Number(id) : undefined,
  };
};


export { getTeamDefaultValues };